import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  MenuItem,
  TextField,
  FormControl,
  Typography,
  InputLabel,
  Select,
  Link,
  Box,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import uploadpicture from "../img/uploadpicture.png";
import { useForm } from "react-hook-form";
import "../css/loginform.css";
import signup from "../img/signup.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, useNavigate } from "react-router-dom";
import LogoImg from "./logo";
import { Input } from "@mui/icons-material";
import { networkRequest } from "../utils/network-request";
import { apiUrl } from "../config/api-urls";
import { useDispatch, useSelector } from "react-redux";
import { addSession } from "../redux/slice/session";
import { usersEnum } from "../config/enum";
import { useUserProfile } from "../context/profile.contex";
import format from "date-fns/format";

export default function IndividualSignupForm() {
  const { handleSubmit, register } = useForm();
  const [genderType, setGenderType] = useState(""); 
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);
  const [accountType, setAccountType] = useState("usersEnum.NORMAL");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [bioData, setBioData] = useState("");
  const { user, isLoading, error, checkUserProfile, setUserProfile } =
    useUserProfile();

  
  const handleGenderTypeChange = (event) => {
    setGenderType(event.target.value);
  };

  const handleImageUpload = (event) => {
    const files = event.target.files;
    setSelectedImage(files[0]);
  };

  const triggerFileSelect = () => {
    fileInputRef?.current?.click();
  };

  function handleStateChange(e) {
    const value = e.target.value || "";
    switch (e.target.name) {
      case "phoneNumber":
        setPhoneNumber(value);
        break;
      case "dateOfBirth":
        setDateOfBirth(value);
        break;
      case "bioData":
        setBioData(value);
        break;
      case "genderType":
        setGenderType(value);
        break;
      default:
        break;
    }
  }

  async function changeProfile() {
    if (!selectedImage) return;
    const form = new FormData();
    form.append("profilePicture", selectedImage);
    const data = await networkRequest(
      "patch",
      apiUrl.ADD_PROFILE_PICTURE,
      form
    );
    console.log("data.user", data.data.user);
    setUserProfile(data.data.user);
  }

  useEffect(() => {
    changeProfile();
  }, [selectedImage]);

  const onSubmit = async (data) => {
    try {
      const response = await networkRequest("patch", apiUrl.USER_PROFILE, {
        ...data,
        userId: user.userId,
        type: usersEnum.NORMAL,
        dateOfBirth: dateOfBirth,
      });
      if (response) {
        setUserProfile(response.data);
        navigate("/socials");
      }
    } catch (error) {
      console.error("Error signing up:", error);
    }
  };

  return (
    <>
      <div className="container">
        <LogoImg />
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="form">
              <Box sx={{ justifyContent: "center", alignItems: "center" }}>
                <div
                  className="title py-5"
                  style={{ flexDirection: "column", alignItems: "flex-start" }}
                >
                  <Typography
                    variant="h1"
                    component="h1"
                    sx={{
                      fontWeight: "700",
                      fontSize: "45px",
                      fontFamily: "poppins, semiBold",
                    }}
                  >
                    Individual Sign Up
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ mb: 4, fontFamily: "poppins", fontSize: "14px" }}
                  >
                    Optional information
                  </Typography>
                </div>

                {/* Picture Upload */}
                <img
                  src={user?.profilePicture}
                  alt="google"
                  width={50}
                  height={50}
                />
                <Button
                  type="file"
                  variant="contained"
                  color="primary"
                  onClick={triggerFileSelect}
                  sx={{ ml: 3 }}
                  id="file-upload"
                  style={{
                    borderRadius: "30px",
                    height: "47px",
                    fontFamily: "poppins",
                    fontWeight: "400",
                    fontSize: "14px",
                    background:
                      "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                  }}
                  onChange={handleStateChange}
                >
                  <img
                    src={uploadpicture}
                    alt="uploadpicture"
                    width={30}
                    height={30}
                    style={{ padding: 5 }}
                  />
                  Upload a Picture
                </Button>

                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                  multiple={false}
                />

                {/* Information */}
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    width: "450px",
                    marginTop: "50px",
                  }}
                >
                  {/* Phone Number Field */}
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    {...register("phoneNumber")}
                    InputProps={{
                      style: {
                        borderRadius: "30px",
                        backgroundColor: "#F9F9F9",
                        "& .MuiInputLabel-asterisk": { color: "red" },
                      },
                    }}
                  />

                  {/* About me Field */}
                  <TextField
                    label="About Me..."
                    variant="outlined"
                    {...register("bio")}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        backgroundColor: "#F9F9F9",
                        height: "60px",
                      },
                    }}
                  />

                  <div className="row">
                    <div className="col-6">
                      {/* Date of Birth Field */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Date of Birth"
                          value={dateOfBirth}
                          onChange={(newValue) => setDateOfBirth(newValue)}
                          format="dd/MM/yyyy"
                          renderInput={(params) => (
                            <TextField fullWidth margin="normal" {...params} />
                          )}
                          sx={{
                            mb: 2,
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "30px",
                              border: "1px solid #d3d3d3",
                              padding: "1px 14px",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </div>

                    <div className="col-6">
                      {/* Gender Field */}
                      <FormControl
                        fullWidth
                        variant="outlined"
                        sx={{ float: "right" }}
                      >
                        <InputLabel id="gender-type-label">Gender</InputLabel>
                        <Select
                          labelId="gender-type-label"
                          value={genderType}
                          onChange={handleGenderTypeChange}
                          label="Select Gender"
                          sx={{
                            borderRadius: "30px",
                            backgroundColor: "#F9F9F9",
                          }}
                        >
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {/* Location Field */}
                  <TextField
                    label="Location"
                    variant="outlined"
                    {...register("location")}
                    InputProps={{
                      style: {
                        borderRadius: "30px",
                        backgroundColor: "#F9F9F9",
                      },
                    }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 3,
                      mt: 1,
                      flexDirection: "row-reverse",
                    }}
                  >
                    <Link
                      onClick={() => navigate("/socials")}
                      underline="none"
                      sx={{
                        fontSize: 14,
                        fontFamily: "poppins",
                        fontWeight: 600,
                        color: "#9A00A9",
                        textDecoration: "none",
                      }}
                    >
                      Skip for Now
                    </Link>
                  </Box>

                  {/* Submit Button */}
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mb: 2 }}
                    style={{
                      borderRadius: "30px",
                      height: "47px",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      background:
                        "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                    }}
                    type="submit"
                  >
                    Next
                  </Button>
                </form>
              </Box>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="form-img ">
              <img src={signup} alt="signup" style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
