import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  IconButton,
  Typography,
  Grid,
  TextField,
  Link,
  InputAdornment,
  Modal,
  Box,
} from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import "../css/socialmedia.css";
import NavBar from "./navbar";
import Container from "react-bootstrap/Container";
import johntomas from "../img/jhontomas.png";
import gallery from "../img/gallery.png";
import videos from "../img/videos.png";
import emoji from "../img/emoji.png";
import podcost from "../img/podcost.png";
import poll from "../img/poll.png";
import science from "../img/science.png";
import scienceship from "../img/scienceship.png";
import likes from "../img/likes.png";
import comment from "../img/comment.png";
import report from "../img/report.png";
import upload from "../img/upload.png";
import save from "../img/save.png";
import quilling from "../img/quilling.png";
import nature from "../img/nature.png";
import bird from "../img/bird.png";
import RightSide from "./rightside";
import choosephoto from "../img/choosephoto.png";
import choosevideo from "../img/choosevideo.png";
import editimg from "../img/editimg.png";
import InputEmoji from "react-input-emoji";
import axios from "axios";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import homepage from "../img/homepage.mp4";
import { networkRequest } from "../utils/network-request";
import { apiUrl } from "../config/api-urls";
import { toast } from "react-toastify";
import CommentsModal from "./comments-modal";
import { Navigate, Link as RouterLink, useNavigate } from "react-router-dom";
import LeftSide from "./leftside";
import { useUserProfile } from "../context/profile.contex";
import likess from "../img/likess.png";
import moment from "moment-timezone";
import { Link as MuiLink } from "@mui/material";

const contentEnum = {
  IMAGE: "image",
  VIDEO: "video",
  PODCAST: "podcast",
  EMOJI: "emoji",
  POLL: "poll",
};

export default function SocialMedia() {
  const [open, setOpen] = useState(false);
  const [postContent, setPostContent] = useState("");
  const [contentType, setContentType] = useState("");
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showMore, setShowMore] = useState({});
  const [fullscreenMedia, setFullscreenMedia] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [posts, setPosts] = useState([]);
  const [createdAt, setCreatedAt] = useState(null);
  const [scroll, setScroll] = useState(null);
  const [commentModal, setCommentModal] = useState(false);
  const { user, isLoading, error, checkUserProfile } = useUserProfile();
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFullscreenOpen = (mediaUrl) => {
    setFullscreenMedia(mediaUrl);
  };

  const contetnSelector = (content) => {
    setContentType(content);
  };

  const toggleCommentBox = (postId) => {
    setCommentModal((prevPostId) => {
      if (!prevPostId && postId) {
        return postId;
        navigate('/socialmedia');
      } else {
        return false;
      }
    });
  };

  const checkButtonValidation = () => {
    const horse = postContent.trim().split(/\s+/).join("");
    const characterCount = horse.length || 0;
    console.log("characterCountcharacterCount", characterCount);
    if (selectedVideos.length || selectedImages.length || characterCount < 500) {
      return false;
    }
    return true;
  };

  const handleImageUpload = (event) => {
    const files = event.target.files;
    const imageUrls = Array.from(files).map((file) => {
      return { file: file, imageUrl: URL.createObjectURL(file) };
    });
    setSelectedImages((prevImages) => [...prevImages, ...imageUrls]);
  };

  const handleVideoUpload = (event) => {
    const files = event.target.files;
    const videoUrls = Array.from(files).map((file) => {
      return { file: file, videoUrl: URL.createObjectURL(file) };
    });
    setSelectedVideos((prevVideos) => [...prevVideos, ...videoUrls]);
  };

  const handleRemoveImage = (imageUrl) => {
    setSelectedImages((prevImages) =>
      prevImages.filter((image) => image.imageUrl !== imageUrl)
    );
  };

  const handleEditImage = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsEditing(true);
  };

  const handleEditVideo = (videoUrl) => {
    setSelectedImage(videoUrl);
    setIsEditing(true);
  };

  const handleRemoveVideo = (videoUrl) => {
    setSelectedVideos((prevVideos) =>
      prevVideos.filter((video) => video?.videoUrl !== videoUrl)
    );
  };

  const handleContentChange = (value) => {
    if (!value) value = "";
    setPostContent(value);
  };

  const handleShowMore = (postId) => {
    console.log("postId", postId);
    setShowMore((prevState) => ({
      ...prevState,
      [postId]: !prevState[postId],
    }));
  };

  function handleOnEnter(text) {
    console.log("enter", text);
  }

  async function manageLike(e, post) {
    if (!post?.isLiked) {
      await networkRequest("post", apiUrl.CREATE_LIKE, {
        postId: post?.postId,
      });

      setPosts((prev) => {
        const prePost = prev.find((prePost) => prePost.postId === post?.postId);
        prePost.likeCount += 1;
        prePost.isLiked = true;
        return structuredClone(prev);
      });
    } else {
      await networkRequest("delete", apiUrl.DELETE_LIKE, {
        postId: post?.postId,
      });

      setPosts((prev) => {
        const prePost = prev.find((prePost) => prePost.postId === post?.postId);
        prePost.likeCount -= 1;
        prePost.isLiked = false;
        return structuredClone(prev);
      });
    }
  }

  async function deleteLike(post) { }

  async function manageFollow(e, post) {
    if (!post?.user?.isFollowed) {
      await networkRequest("post", apiUrl.USER_FOLLOW, {
        followedId: post?.user?.userId,
      });

      setPosts((prev) => {
        for (let i = 0; i < prev.length; i++) {
          const oldPost = prev[i];
          if (oldPost?.user?.userId === post?.user?.userId) {
            oldPost.user.isFollowed = true;
          }
        }
        return structuredClone(prev);
      });
    } else {
      await networkRequest("delete", apiUrl.USER_UN_FOLLOW, {
        followedId: post.user.userId,
      });

      setPosts((prev) => {
        for (let i = 0; i < prev.length; i++) {
          const oldPost = prev[i];
          if (oldPost?.user?.userId === post?.user?.userId) {
            oldPost.user.isFollowed = false;
          }
        }
        return structuredClone(prev);
      });
    }
  }

  async function createNewPost(e) {
    e?.preventDefault();
    const post = new FormData();
    if (postContent) {
      post.append("content", postContent);
    }
    if (selectedImages.length) {
      for (const image of selectedImages) {
        post.append("media", image.file);
      }
    }
    if (selectedVideos.length) {
      for (const video of selectedVideos) {
        post.append("media", video.file);
      }
    }
    if (!post.has("media") && !post.has("content")) {
      return toast("Content Needed For Post");
    }
    const response = await networkRequest("post", apiUrl.CREATE_POST, post);
    if (response?.statusCode !== 201) {
      return toast("Something Went Wrong");
    }

    setPosts((prev) => {
      prev.unshift(response.data);
      return structuredClone(prev);
    });

    setPostContent("");
    setSelectedVideos([]);
    setSelectedImages([]);

    handleClose();
  }

  async function getPosts() {
    const query = {};
    if (createdAt) {
      query.createdAt = createdAt;
    }
    const response = await networkRequest(
      "get",
      apiUrl.GET_POSTS,
      {},
      {},
      query
    );

    if (response?.statusCode !== 200 || !Array.isArray(response?.data?.posts)) {
      return toast("Something Went Wrong");
    }

    setCreatedAt(response.data.createdAt);
    setPosts((prev) => prev.concat(response.data.posts));
  }

  function onComment(postId) {
    setPosts((prev) => {
      const index = prev.findIndex((obj) => obj.postId === postId);
      const post = prev[index];
      if (!post) {
        return prev;
      }
      post.commentCount += 1;
      return structuredClone(prev);
    });
  }

  useEffect(() => {
    getPosts();
  }, [scroll]);

  return (
    <>
      <NavBar />
      {!isLoading && (
        <Container>
          <Grid container spacing={2}>
            {/* Left Sidebar */}
            <Grid item xs={3}>
              <LeftSide />
            </Grid>

            {/* Main Feed Section */}
            <Grid item xs={6}>
              <div className="create-post-section">
                <div className="create-post-title">
                  <h4>Create Post</h4>
                </div>
                <div className="create-post-detail">
                  <div className="on-mind d-flex align-items-center">
                    <Avatar src={user?.profilePicture} alt="John Tomas" />
                    <TextField
                      variant="outlined"
                      placeholder={`What's on your mind, ${user?.fullName || ""
                        }?`}
                      fullWidth
                      className="create-post-input bg-light no-border responsive-width"
                      InputProps={{
                        style: {
                          borderRadius: "30px",
                          backgroundColor: "#f8f9fa",
                        },
                        onClick: handleOpen,
                      }}
                    />
                  </div>
                  <div className="create-post-options d-flex align-items-center">
                    <Button
                      variant="text"
                      className="post-option"
                      sx={{
                        width: "84px",
                        height: "24px",
                        mr: 2,
                        fontSize: "14px",
                        fontFamily: "Poppins, Regular",
                        textTransform: "none",
                      }}
                    >
                      <img
                        src={gallery}
                        alt="Gallery"
                        className="post-option-icon"
                      />
                      Gallery
                    </Button>
                    <Button
                      variant="text"
                      className="post-option"
                      sx={{
                        width: "84px",
                        height: "24px",
                        mr: 2,
                        fontSize: "14px",
                        fontFamily: "Poppins, Regular",
                        textTransform: "none",
                      }}
                    >
                      <img
                        src={videos}
                        alt="Video"
                        className="post-option-icon"
                      />
                      Video
                    </Button>
                    <Button
                      variant="text"
                      className="post-option"
                      sx={{
                        width: "84px",
                        height: "24px",
                        mr: 2,
                        fontSize: "14px",
                        fontFamily: "Poppins, Regular",
                        textTransform: "none",
                      }}
                    >
                      <img
                        src={podcost}
                        alt="Podcast"
                        className="post-option-icon"
                      />
                      Podcast
                    </Button>
                    <Button
                      variant="text"
                      className="post-option"
                      sx={{
                        width: "84px",
                        height: "24px",
                        mr: 2,
                        fontSize: "14px",
                        fontFamily: "Poppins, Regular",
                        textTransform: "none",
                      }}
                    >
                      <img src={poll} alt="Poll" className="post-option-icon" />
                      Poll
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        width: "89px",
                        height: "36px",
                        fontSize: "14px",
                        fontFamily: "Poppins, Regular",
                        textTransform: "none",
                        background:
                          "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                        color: "#fff",
                        borderRadius: "30px",
                        "&:hover": {
                          background:
                            "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                          opacity: 0.9,
                        },
                      }}
                    >
                      Post
                    </Button>
                  </div>
                </div>
              </div>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: { xs: "90%", sm: "70%", md: "50%" },
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: "16px",
                    p: 4,
                  }}
                >
                  {fullscreenMedia && (
                    <>
                      {fullscreenMedia.endsWith(".mp4") ? (
                        <video
                          src={fullscreenMedia}
                          controls
                          style={{ width: "100%", height: "100%" }}
                        />
                      ) : (
                        <img
                          src={fullscreenMedia}
                          alt="Full Preview"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      )}
                    </>
                  )}
                  <div className="modal-create-post-section">
                    <div className="modal-create-post-title">
                      <h4>Create Post</h4>
                    </div>
                    <div className="modal-create-post-detail">
                      <div className="modal-on-mind">
                        <div className="modal-users-detail mb-3">
                          <Avatar src={user?.profilePicture} alt="John Tomas" />
                          <div className="modal-users-name">
                            <h4>{user?.fullName || user?.businessName}</h4>
                            <h5>@{user?.userName}</h5>
                          </div>
                        </div>
                        <InputEmoji
                          multiline
                          fullWidth
                          value={postContent}
                          onChange={handleContentChange}
                          cleanOnEnter
                          onEnter={handleOnEnter}
                          placeholder="Type a message"
                          inputProps={{
                            maxLength: 500,
                          }}
                          sx={{
                            mb: 2,
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "8px",
                            },
                            "& .MuiInputBase-root": {
                              backgroundColor: "#f8f9fa",
                            },
                          }}
                        />
                        <div style={{ textAlign: "right" }}>
                          <span
                            style={{
                              color:
                                postContent.length >
                                  500
                                  ? "red"
                                  : "inherit",
                            }}
                          >
                            {postContent.length}/500
                          </span>
                        </div>
                      </div>

                      {contentType === contentEnum.IMAGE && (
                        <div
                          className="gallery-upload-section"
                          style={{ position: "relative" }}
                        >
                          <div
                            style={{
                              border: "2px dashed #ccc",
                              borderRadius: "10px",
                              padding: selectedImages.length > 0 ? "0" : "20px",
                              textAlign: "center",
                              backgroundColor: "#f8f9fa",
                              height: "200px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "relative",
                              overflow: "hidden",
                            }}
                          >
                            {selectedImages.length === 0 && (
                              <>
                                <p>Add Photo drag or drop here...</p>
                              </>
                            )}

                            {/* Hidden file input */}
                            <TextField
                              type="file"
                              accept="image/*"
                              onChange={handleImageUpload}
                              sx={{ display: "none" }}
                              id="file-upload"
                            />
                            <label
                              htmlFor="file-upload"
                              style={{
                                position: "relative",
                                top: "20px",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                src={choosephoto}
                                alt=""
                                style={{ width: "40%" }}
                              />
                            </label>

                            {/* Image Preview */}
                            <div
                              className="image-preview-container"
                              style={{
                                marginTop: "16px",
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "10px",
                                width: "100%",
                                height: "auto",
                              }}
                            >
                              {selectedImages.map(({ imageUrl }, index) => (
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                    width: "100%",
                                    height: "200px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    src={imageUrl}
                                    alt={`Selected ${index + 1}`}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "contain",
                                      borderRadius: "10px",
                                      boxShadow:
                                        "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleFullscreenOpen(imageUrl)
                                    }
                                  />
                                  <Button
                                    onClick={() => handleEditImage(imageUrl)}
                                    sx={{
                                      position: "absolute",
                                      top: "0px",
                                      left: "-38px",
                                      minWidth: "24px",
                                      minHeight: "24px",
                                      borderRadius: "50%",
                                      padding: "0",
                                      background: "none",
                                    }}
                                  >
                                    <img
                                      src={editimg}
                                      alt=""
                                      style={{ width: "25%" }}
                                    />
                                  </Button>
                                  <Button
                                    onClick={() => handleRemoveImage(imageUrl)}
                                    sx={{
                                      position: "absolute",
                                      top: "5px",
                                      right: "5px",
                                      minWidth: "24px",
                                      minHeight: "24px",
                                      borderRadius: "50%",
                                      padding: "0",
                                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                                      color: "#fff",
                                      "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                                      },
                                    }}
                                  >
                                    &times;
                                  </Button>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}

                      {contentType === contentEnum.VIDEO && (
                        <div
                          className="video-upload-section"
                          style={{ position: "relative" }}
                        >
                          <div
                            style={{
                              border: "2px dashed #ccc",
                              borderRadius: "10px",
                              padding: selectedVideos.length ? "0" : "20px",
                              textAlign: "center",
                              backgroundColor: selectedVideos.length
                                ? "transparent"
                                : "#f8f9fa",
                              height: "200px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "relative",
                              overflow: "hidden",
                            }}
                          >
                            {!selectedVideos.length && (
                              <>
                                <p>Add Video drag or drop here...</p>
                              </>
                            )}
                            <TextField
                              type="file"
                              accept="video/*"
                              onChange={handleVideoUpload}
                              sx={{ display: "none" }}
                              id="video-upload"
                              multiple
                            />
                            <label
                              htmlFor="video-upload"
                              style={{
                                position: "relative",
                                top: "20px",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                src={choosevideo}
                                alt=""
                                style={{ width: "40%" }}
                              />
                            </label>
                            <div
                              className="video-preview-container"
                              style={{
                                marginTop: "16px",
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "10px",
                                width: "100%",
                                height: "auto",
                              }}
                            >
                              {selectedVideos.map(({ videoUrl }, index) => (
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                    width: "100%",
                                    height: "200px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <video
                                    src={videoUrl}
                                    controls
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "contain",
                                      borderRadius: "10px",
                                      boxShadow:
                                        "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleFullscreenOpen(videoUrl)
                                    }
                                  />
                                  <Button
                                    onClick={() => handleEditVideo(videoUrl)}
                                    sx={{
                                      position: "absolute",
                                      top: "0px",
                                      left: "-38px",
                                      minWidth: "24px",
                                      minHeight: "24px",
                                      borderRadius: "50%",
                                      padding: "0",
                                      background: "none",
                                    }}
                                  >
                                    <img
                                      src={editimg}
                                      alt=""
                                      style={{ width: "25%" }}
                                    />
                                  </Button>
                                  <Button
                                    onClick={() => handleRemoveVideo(videoUrl)}
                                    sx={{
                                      position: "absolute",
                                      top: "5px",
                                      right: "5px",
                                      minWidth: "24px",
                                      minHeight: "24px",
                                      borderRadius: "50%",
                                      padding: "0",
                                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                                      color: "#fff",
                                      "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                                      },
                                    }}
                                  >
                                    &times;
                                  </Button>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="modal-create-post-options">
                        <div className="modal-post-icon">
                          <Button
                            variant="text"
                            className="post-option"
                            sx={{ height: "24px", mr: 2 }}
                            onClick={() => contetnSelector(contentEnum.IMAGE)}
                          >
                            {" "}
                            <img
                              src={gallery}
                              alt="Gallery"
                              className="post-option-icon"
                            />
                          </Button>
                          <Button
                            variant="text"
                            className="post-option"
                            sx={{ height: "24px", mr: 2 }}
                            onClick={() => contetnSelector(contentEnum.VIDEO)}
                          >
                            {" "}
                            <img
                              src={videos}
                              alt="Video"
                              className="post-option-icon"
                            />
                          </Button>
                          <Button
                            variant="text"
                            className="post-option"
                            sx={{ height: "24px", mr: 2 }}
                            onClick={() => contetnSelector(contentEnum.PODCAST)}
                          >
                            {" "}
                            <img
                              src={podcost}
                              alt="Podcast"
                              className="post-option-icon"
                            />
                          </Button>
                          {/* <Button variant="text" className="post-option" sx={{ height: '24px', mr: 2, }} onClick={() => contetnSelector(contentEnum.EMOJI)}> <img src={emoji} alt="GIF/Emoji" className="post-option-icon" />
                        </Button> */}
                          <Button
                            variant="text"
                            className="post-option"
                            sx={{ height: "24px", mr: 2 }}
                            onClick={() => contetnSelector(contentEnum.POLL)}
                          >
                            {" "}
                            <img
                              src={poll}
                              alt="Poll"
                              className="post-option-icon"
                            />
                          </Button>
                        </div>
                        <div className="model-post-btn">
                          <Button
                            variant="contained"
                            disabled={postContent.length > 500}
                            onClick={createNewPost}
                            sx={{
                              width: "89px",
                              height: "36px",
                              fontSize: "14px",
                              fontFamily: "Poppins, Regular",
                              textTransform: "none",
                              background:
                                "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                              color: "#fff",
                              borderRadius: "30px",
                              "&:hover": {
                                background:
                                  "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                                opacity: 0.9,
                              },
                            }}
                          >
                            Post
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>

              {/* Post Feed */}
              <div className="feed-section">
                {posts?.map((post, index) => {
                  return (
                    <div className="post">
                      <div className="post-header mb-4" style={{ gap: "0px" }}>
                        <MuiLink
                          component={RouterLink}
                          to={`/individualsocial/${post.userId}`}
                          state={{ textDecoration: "none !important" }}
                        >
                          <div className="post-headers">
                            <Avatar src={post?.user?.profilePicture} />
                            <div
                              className="post-author-info"
                              style={{
                                textDecoration: "none !important",
                                color: "#000",
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: "Poppins, Medium",
                                  fontWeight: "500",
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {post?.user?.fullName ||
                                  post?.user?.businessName}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "14px",
                                  fontFamily: "Poppins, Regular",
                                  textDecoration: "none !important",
                                  color: "black",
                                }}
                              >
                                @{post?.user?.userName}
                              </Typography>
                            </div>
                          </div>
                        </MuiLink>

                        <div className="post-btn">
                          {user?.userId !== post?.user?.userId && (
                            <Button
                              variant="text"
                              className="following-btn"
                              value={
                                post?.user?.isFollowed ? "Following" : "follow"
                              }
                              sx={{
                                ml: 25,
                                fontSize: "14px",
                                fontFamily: "Poppins, Regular",
                                fontWeight: "500",
                              }}
                              onClick={(e) => manageFollow(e, post)}
                            >
                              {post?.user?.isFollowed ? "Following" : "Follow"}
                            </Button>
                          )}
                          <IconButton
                            sx={{
                              border: "2px solid black",
                              width: "26px",
                              height: "26px",
                              color: "black",
                            }}
                          >
                            <MoreHoriz />
                          </IconButton>
                        </div>
                      </div>
                      <Typography variant="body2" className="post-content">
                        {post?.content?.length > 200 ? (
                          <>
                            {showMore[post.postId] ? (
                              post.content
                            ) : (
                              <>
                                {post.content.substring(0, 200)}...
                                <Button
                                  variant="text"
                                  sx={{
                                    fontSize: "12px",
                                    padding: "0",
                                    minWidth: "unset",
                                  }}
                                  onClick={() => handleShowMore(post.postId)}
                                >
                                  More
                                </Button>
                              </>
                            )}
                          </>
                        ) : (
                          post.content
                        )}
                      </Typography>
                      {post?.media?.map((media) => {
                        if (media?.type === "photos") {
                          return (
                            <Zoom>
                              <div className="post-image">
                                <img src={media.url} alt="Vasa Ship" />
                              </div>
                            </Zoom>
                          );
                        }
                        if (media?.type === "video") {
                          return (
                            <div
                              className="post-image"
                              style={{ height: "500px", width: "600px" }}
                            >
                              <video controls controlsList="nodownload">
                                <source src={media.url} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          );
                        }
                      })}
                      <div
                        className="post-interactions d-flex align-items-center"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div
                          className="feed-icon d-flex align-item-center"
                          style={{ gap: "15px" }}
                        >
                          <IconButton
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              padding: "0px",
                            }}
                            onClick={(e) => manageLike(e, post)}
                          >
                            {post?.isLiked ? (
                              <img
                                src={likess}
                                alt="Like"
                                style={{ width: "20px", height: "20px" }}
                              />
                            ) : (
                              <img
                                src={likes}
                                alt="Like"
                                style={{ width: "20px", height: "20px" }}
                              />
                            )}
                            <span
                              style={{
                                fontSize: "14px",
                                fontFamily: "Poppins, Regular",
                                fontWeight: "300",
                                position: "relative",
                                top: "3px",
                                left: "10px",
                              }}
                            >
                              {post.likeCount}
                            </span>
                          </IconButton>
                          <IconButton
                            sx={{ display: "flex", alignItems: "center" }}
                            onClick={() => toggleCommentBox(post?.postId)}
                          >
                            <img
                              src={comment}
                              alt="Comment"
                              style={{
                                width: "20px",
                                height: "20px",
                                position: "relative",
                                top: "2px",
                              }}
                            />
                            <span
                              style={{
                                fontSize: "14px",
                                fontFamily: "Poppins, Regular",
                                fontWeight: "300",
                                position: "relative",
                                top: "3px",
                                left: "10px",
                              }}
                            >
                              {post.commentCount}
                            </span>
                          </IconButton>

                          <IconButton
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={report}
                              alt="Report"
                              style={{
                                width: "20px",
                                height: "20px",
                                position: "relative",
                                top: "2px",
                              }}
                            />
                            <span
                              style={{
                                fontSize: "14px",
                                fontFamily: "Poppins, Regular",
                                fontWeight: "300",
                                position: "relative",
                                top: "3px",
                                left: "10px",
                              }}
                            >
                              45
                            </span>
                          </IconButton>
                        </div>

                        <div className="bookmarkrs d-flex align-items-center">
                          <IconButton
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={upload}
                              alt="Upload"
                              style={{
                                width: "20px",
                                height: "20px",
                                position: "relative",
                                top: "2px",
                              }}
                            />
                          </IconButton>
                          <IconButton
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              padding: "0px",
                            }}
                          >
                            <img
                              src={save}
                              alt="Save"
                              style={{
                                width: "20px",
                                height: "20px",
                                position: "relative",
                                top: "2px",
                              }}
                            />
                          </IconButton>
                        </div>
                      </div>
                      <div className="post-detail">
                        <p style={{ width: "130px", margin: "0px" }}>
                          {moment().diff(moment(post.createdAt), "hours") < 24
                            ? moment(post.createdAt)
                              .tz(moment.tz.guess())
                              .fromNow()
                            : moment(post.createdAt)
                              .tz(moment.tz.guess())
                              .format("Do MMMM YYYY")}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Grid>

            {/* Right Sidebar (Suggested for You) */}
            <Grid item xs={3}>
              <RightSide />
            </Grid>
          </Grid>
        </Container>
      )}

      {commentModal && (
        <CommentsModal
          onClose={toggleCommentBox}
          open={commentModal}
          postId={commentModal}
          onComment={onComment}
        />
      )}
    </>
  );
}
