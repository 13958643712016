import React, { useEffect, useRef, useState } from "react";
import { Button, Typography, Box, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "../css/signupform.css";
import axios from "axios";
import LogoImg from "./logo";
import resetpassword from "../img/resetpassword.png";
import { networkRequest } from "../utils/network-request";
import { apiUrl } from "../config/api-urls";
import { toast } from "react-toastify";

export default function ResetPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [otpSent, setOtpSent] = useState(false); 
  const [otp, setOtp] = useState(""); 
  const [email, setEmail] = useState(""); 
  const [otpVerified, setOtpVerified] = useState(false); 
  const navigate = useNavigate();
  const isMount = useRef(false);

  // API call for OTP generation
  const generateOtp = async () => {
    try {
      const form = new FormData();
      form.append("email", email);

      const response = await networkRequest(
        "post",
        apiUrl.RESET_PASSWORD_OTP,
        form
      );

      if (!response || !response?.message === "sign-up otp sent successfully") {
        setOtpSent(false);
        return toast("OTP Not Sent Please Try Again");
      }

      toast(response?.message);

      return setOtpSent(true);
    } catch (error) {
      setOtpSent(false);
    }
  };

  // API call for OTP verification
  const verifyOtp = async () => {
    const form = new FormData();
    form.append("email", email);
    form.append("otp", otp);

    const response = await networkRequest(
      "patch",
      apiUrl.RESET_PASSWORD_OTP_VERIFY,
      form
    );

    if (!response || !response?.data?.isVerified) {
      setOtpSent(false);
      return toast("OTP Not Sent Please Try Again");
    }

    setOtpVerified(true);

    navigate("/newpassword", { state: { email: email } });
  };

  useEffect(() => {
    if (isMount.current && email) {
      generateOtp();
    } else {
      isMount.current = true;
    }
  }, [email]);

  // // Form Submission Handler
  const onSubmit = (data) => {
    setEmail(data.email);
  };

  return (
    <>
      <div className="container">
        <LogoImg />
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="account">
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ flex: 1, paddingTop: "20px" }}>
                  <Typography
                    variant="h2"
                    component="h2"
                    sx={{
                      fontWeight: "700",
                      fontSize: "45px",
                      fontFamily: "Poppins, sans-serif",
                      mb: 2,
                    }}
                  >
                    Reset Password
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 5,
                      fontSize: "14px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Forgot your password? Please enter your email and we'll send
                    you a 4 digit code.
                  </Typography>

                  <TextField
                    fullWidth
                    label="Email Address"
                    type="email"
                    required
                    sx={{
                      mb: 2,
                      "& .MuiOutlinedInput-root": { borderRadius: "30px" },
                      "& .MuiInputLabel-asterisk": { color: "red" },
                    }}
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                        message: "Enter a valid email address",
                      },
                    })}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ""}
                  />

                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ mt: 2, borderRadius: "30px", height: "50px" }}
                    onClick={handleSubmit(onSubmit)}
                    style={{
                      background:
                        "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                    }}
                  >
                    Get 4-Digit Code
                  </Button>

                  {otpSent && (
                    <Box sx={{ mt: 3 }}>
                      <TextField
                        fullWidth
                        label="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        sx={{
                          mb: 2,
                          "& .MuiOutlinedInput-root": { borderRadius: "30px" },
                        }}
                      />
                      <Button
                        variant="contained"
                        onClick={verifyOtp}
                        fullWidth
                        sx={{ borderRadius: "30px", height: "50px" }}
                        style={{
                          background:
                            "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                        }}
                      >
                        Verify OTP
                      </Button>
                      {otpVerified && (
                        <Typography
                          variant="body2"
                          sx={{ mt: 2, color: "green" }}
                        >
                          OTP Verified!
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="form-img">
              <img
                src={resetpassword}
                alt="Reset Password"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
