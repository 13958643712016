import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import activatree2 from "../img/activatree2.svg";
import home from "../img/home.png";
import homeactive from "../img/homeactive.png";
import socialmedia from "../img/socialmedia.png";
import socialmediaactive from "../img/socialmediaactive.png";
import workspace from "../img/workspace.png";
import workspaceactive from "../img/workspaceactive.png";
import marketplace from "../img/marketplace.png";
import marketplaceactive from "../img/marketplaceactive.png";
import discussionforum from "../img/discussionforum.png";
import discussionforumactive from "../img/discussionforumactive.png";
import newschannel from "../img/newschanel.png";
import newschannelactive from "../img/newschannelactive.png";
import { useNavigate, useLocation } from "react-router-dom";
import "../css/navbar.css";
import "../css/style.css";
import { useUserProfile } from "../context/profile.contex";

export default function NavBar() {
  const [activeLink, setActiveLink] = useState("home");
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isLoading, error, checkUserProfile } = useUserProfile();

  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  // const { user } = useUserProfile();

  useEffect(() => {
    const path = location?.pathname;
    if (path.includes("socialmedia")) {
      setActiveLink("socialmedia");
    } else if (path.includes("workspace")) {
      setActiveLink("workspace");
    } else if (path.includes("marketplace")) {
      setActiveLink("marketplace");
    } else if (path.includes("discussionforum")) {
      setActiveLink("discussionforum");
    } else if (path.includes("newschannel")) {
      setActiveLink("newschannel");
    } else {
      setActiveLink("home");
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        // Scrolling down
        setShowNavbar(false);
      } else {
        // Scrolling up
        setShowNavbar(true);
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const handleLinkClick = (link, path) => {
    setActiveLink(link);
    navigate(path);
  };

  const handleLoginClick = () => {
    navigate("/loginform");
  };

  const handleJoinNowClick = () => {
    navigate("/signup");
  };

  return (
    <>
      <div>
        <div>
          <Navbar expand="lg" className="mb-5" style={{
            position: "relative",
            top: showNavbar ? "0" : "-80px", 
            width: "100%",
            zIndex: "1000",
            transition: "top 0.3s ease-in-out", 
            backgroundColor: "white", 
          }}>
            <Container>
              <Navbar.Brand
                href=""
                to="/"
                onClick={() => handleLinkClick("homepage", "/")}
                style={{ cursor: 'pointer' }}
              >
                <div className="logo-img">
                  <img
                    src={activatree2}
                    alt="Activa Tree Logo"
                    style={{ width: "100%" }}
                  />
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className={`ms-auto ${user ? "me-0" : ""}`}>
                  <Nav.Link
                    href=""
                    to="/"
                    onClick={() => handleLinkClick("homepage", "/")}
                    className={
                      activeLink === "home" ? "nav-link active" : "nav-link"
                    }
                  >
                    <img
                      src={activeLink === "home" ? homeactive : home}
                      alt="Home Page"
                      style={{ width: "25%" }}
                    />
                    <p>
                      <span>Home</span>
                    </p>
                  </Nav.Link>
                  <Nav.Link
                    href=""

                    onClick={() =>
                      handleLinkClick("socialmedia", "/socialmedia")
                    }
                    className={
                      activeLink === "socialmedia"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img
                      src={
                        activeLink === "socialmedia"
                          ? socialmediaactive
                          : socialmedia
                      }
                      alt="Social Media"
                      style={{ width: "25%" }}
                    />
                    <p>
                      <span>Social Media</span>
                    </p>
                  </Nav.Link>
                  <Nav.Link
                    href=""
                    to="/marketplace"
                    onClick={() =>
                      handleLinkClick("marketplace", "/marketplace")
                    }
                    className={
                      activeLink === "marketplace"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img
                      src={
                        activeLink === "marketplace"
                          ? marketplaceactive
                          : marketplace
                      }
                      alt="Market Place"
                      style={{ width: "25%" }}
                    />
                    <p>
                      <span>Market Place</span>
                    </p>
                  </Nav.Link>
                  <Nav.Link
                    href=""
                    to="/discussionforum"
                    onClick={() =>
                      handleLinkClick("discussionforum", "/discussionforum")
                    }
                    className={
                      activeLink === "discussionforum"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img
                      src={
                        activeLink === "discussionforum"
                          ? discussionforumactive
                          : discussionforum
                      }
                      alt="Discussion Forum"
                      style={{ width: "25%" }}
                    />
                    <p>
                      <span>Discussion Forum</span>
                    </p>
                  </Nav.Link>
                </Nav>
                {!user && (
                  <>
                    <Button
                      variant="contained"
                      color="white"
                      onClick={handleLoginClick}
                      sx={{
                        borderRadius: "30px",
                        border: "1px solid black",
                        width: "110px",
                        height: "44px",
                        fontFamily: "poppins",
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#000",
                        textTransform: "math-auto",
                      }}
                    >
                      Log In
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleJoinNowClick}
                      sx={{
                        borderRadius: "30px",
                        padding: "0px",
                        ml: 2,
                        width: "115px",
                        height: "44px",
                        fontFamily: "poppins",
                        fontWeight: "500",
                        fontSize: "16px",
                        background:
                          "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                        textTransform: "math-auto",
                      }}
                    >
                      Join Now
                    </Button>
                  </>
                )}
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    </>
  );
}
