import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  IconButton,
  Button,
  Avatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { apiUrl } from "../config/api-urls";
import { useNavigate } from "react-router-dom";
import { useUserProfile } from "../context/profile.contex";
import { toast } from "react-toastify";
import { networkRequest } from "../utils/network-request";
import InputEmoji from "react-input-emoji";
import { contentEnum } from "../enum/content";
import choosephoto from "../img/choosephoto.png";
import editimg from "../img/editimg.png";
import choosevideo from "../img/choosevideo.png";
import gallery from "../img/gallery.png";
import videos from "../img/videos.png";
import emoji from "../img/emoji.png";
import podcost from "../img/podcost.png";
import poll from "../img/poll.png";

export default function EditPostModal({ isOpen, onClose, post, onSave }) {
  const [open, setOpen] = useState(false);
  const [postContent, setPostContent] = useState("");
  const [contentType, setContentType] = useState("");
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedImages, setSelectedImages] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [showMore, setShowMore] = useState({});
  const [fullscreenMedia, setFullscreenMedia] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [posts, setPosts] = useState([]);
  const [createdAt, setCreatedAt] = useState(null);
  const [scroll, setScroll] = useState(null);
  // const [text, setText] = useState("");
  const { user, isLoading, error, checkUserProfile } = useUserProfile();
  const navigate = useNavigate();
  // const { data } = props;

  function getMediaUrls() {
    const imageUrls = [];
    const videoUrls = [];

    for (const media of post?.media) {
      if (media.type === "photos") {
        imageUrls.push({ imageUrl: media.url });
      } else if (media.type === "video") {
        videoUrls.push({ videoUrl: media.url });
      }
    }
    return { imageUrls, videoUrls };
  }

  useEffect(() => {
    if (post) {
      const { imageUrls, videoUrls } = getMediaUrls();
      setPostContent(post?.content || "");
      setSelectedImages(imageUrls || []);
      setSelectedVideos(videoUrls || []);

      if (imageUrls.length) {
        setContentType(contentEnum.IMAGE);
      } else if (videoUrls.length) {
        setContentType(contentEnum.VIDEO);
      }
    }
  }, [post]);

  const handleClose = () => setOpen(false);

  const handleFullscreenOpen = (mediaUrl) => {
    setFullscreenMedia(mediaUrl);
  };

  const contentSelector = (content) => {
    setContentType(content);
  };

  const handleImageUpload = (event) => {
    const files = event.target.files;
    console.log("Selected files:", files);
    const imageUrls = Array.from(files).map((file) => {
      return { file: file, imageUrl: URL.createObjectURL(file) };
    });
    setSelectedImages((prevImages) => [...prevImages, ...imageUrls]);
  };

  const handleVideoUpload = (event) => {
    const files = event.target.files;
    const videoUrls = Array.from(files).map((file) => {
      return { file: file, videoUrl: URL.createObjectURL(file) };
    });
    setSelectedVideos((prevVideos) => [...prevVideos, ...videoUrls]);
  };

  const handleRemoveImage = (imageUrl) => {
    setSelectedImages((prevImages) =>
      prevImages.filter((image) => image.imageUrl !== imageUrl)
    );
  };

  const handleEditImage = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsEditing(true);
  };

  const handleEditVideo = (videoUrl) => {
    setSelectedImage(videoUrl);
    setIsEditing(true);
  };

  const handleRemoveVideo = (videoUrl) => {
    setSelectedVideos((prevVideos) =>
      prevVideos.filter((video) => video?.videoUrl !== videoUrl)
    );
  };

  const handleContentChange = (value) => {
    if (!value) value = "";
    setPostContent(value);
  };

  function handleOnEnter(text) {
    console.log("enter", text);
  }

  async function handleSavePost(e) {
    e?.preventDefault();
    if (!post.postId) {
      throw new Error("postId not exist");
    }
    const postData = new FormData();
    postData.append("postId", post.postId);
    if (postContent) postData.append("content", postContent);

    if (selectedImages.length) {
      for (const image of selectedImages) {
        postData.append("media", image.file);
      }
    }
    if (selectedVideos.length) {
      for (const video of selectedVideos) {
        postData.append("media", video.file);
      }
    }
    if (!postData.has("media") && !postData.has("content")) {
      return toast("Content Needed For Post");
    }

    const response = await networkRequest(
      "patch",
      `${apiUrl.UPDATE_POST}`,
      postData
    );

    if (response?.statusCode !== 201 && response?.statusCode !== 200) {
      return toast("Something Went Wrong");
    }

    response.data.user = user;

    onSave(response.data);
    setPostContent("");
    setSelectedVideos([]);
    setSelectedImages([]);
    onClose();
  }

  async function getPosts() {
    const query = {};
    if (createdAt) {
      query.createdAt = createdAt;
    }
    const response = await networkRequest(
      "get",
      apiUrl.GET_POSTS,
      {},
      {},
      query
    );

    if (response?.statusCode !== 200 || !Array.isArray(response?.data?.posts)) {
      return toast("Something Went Wrong");
    }

    setCreatedAt(response.data.createdAt);
    setPosts((prev) => prev.concat(response.data.posts));
  }

  useEffect(() => {
    getPosts();
  }, [scroll]);

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "70%", md: "50%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "16px",
            p: 4,
          }}
        >
          {fullscreenMedia && (
            <>
              {fullscreenMedia.endsWith(".mp4") ? (
                <video
                  src={fullscreenMedia}
                  controls
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src={fullscreenMedia}
                  alt="Full Preview"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              )}
            </>
          )}
          <div className="modal-create-post-section">
            <div className="modal-create-post-title">
              <h4>{isEditing ? "Edit Post" : "Create Post"}</h4>
            </div>
            <div className="modal-create-post-detail">
              <div className="modal-on-mind">
                <div className="modal-users-detail mb-3">
                  <Avatar src={user?.profilePicture} alt="John Tomas" />
                  <div className="modal-users-name">
                    <h4>{user?.fullName || user?.businessName}</h4>
                    <h5>@{user?.userName}</h5>
                  </div>
                </div>
                <InputEmoji
                  multiline
                  fullWidth
                  value={postContent}
                  onChange={handleContentChange}
                  cleanOnEnter
                  onEnter={handleOnEnter}
                  placeholder="Type a message"
                  inputProps={{
                    maxLength: 500,
                  }}
                  sx={{
                    mb: 2,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                    "& .MuiInputBase-root": {
                      backgroundColor: "#f8f9fa",
                    },
                  }}
                />
                <div style={{ textAlign: "right" }}>
                  <span
                    style={{
                      color: postContent?.length > 500 ? "red" : "inherit",
                    }}
                  >
                    {postContent.length}/500
                  </span>
                </div>
              </div>

              {contentType === contentEnum.IMAGE && (
                <div
                  className="gallery-upload-section"
                  style={{ position: "relative" }}
                >
                  <div
                    style={{
                      border: "2px dashed #ccc",
                      borderRadius: "10px",
                      padding: selectedImages.length > 0 ? "0" : "20px",
                      textAlign: "center",
                      backgroundColor: "#f8f9fa",
                      height: "200px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    {selectedImages.length === 0 && (
                      <>
                        <p>Add Photo drag or drop here...</p>
                      </>
                    )}

                    {/* Hidden file input */}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                      style={{ display: "none" }}
                      id="file-upload"
                    />
                    <button
                      onClick={() => document.getElementById('file-upload').click()}
                      htmlFor="file-upload"
                      style={{
                        position: "relative",
                        top: "20px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        border: 'none',
                        backgroundColor: 'transparent',
                      }}
                    >
                      <img
                        src={choosephoto}
                        alt=""
                        style={{ width: "40%" }}
                      />
                    </button>
                    {/* </TextField> */}

                    {/* Image Preview */}
                    <div
                      className="image-preview-container"
                      style={{
                        marginTop: "16px",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                        width: "100%",
                        height: "auto",
                      }}
                    >
                      {selectedImages.map(({ imageUrl }, index) => (
                        <div
                          key={index}
                          style={{
                            position: "relative",
                            display: "inline-block",
                            width: "100%",
                            height: "200px",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={imageUrl}
                            alt={`Selected ${index + 1}`}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              borderRadius: "10px",
                              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                              cursor: "pointer",
                            }}
                            onClick={() => handleFullscreenOpen(imageUrl)}
                          />
                          <Button
                            onClick={() => handleEditImage(imageUrl)}
                            sx={{
                              position: "absolute",
                              top: "0px",
                              left: "-38px",
                              minWidth: "24px",
                              minHeight: "24px",
                              borderRadius: "50%",
                              padding: "0",
                              background: "none",
                            }}
                          >
                            <img
                              src={editimg}
                              alt=""
                              style={{ width: "25%" }}
                            />
                          </Button>
                          <Button
                            onClick={() => handleRemoveImage(imageUrl)}
                            sx={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              minWidth: "24px",
                              minHeight: "24px",
                              borderRadius: "50%",
                              padding: "0",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.7)",
                              },
                            }}
                          >
                            &times;
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {contentType === contentEnum.VIDEO && (
                <div
                  className="video-upload-section"
                  style={{ position: "relative" }}
                >
                  <div
                    style={{
                      border: "2px dashed #ccc",
                      borderRadius: "10px",
                      padding: selectedVideos.length ? "0" : "20px",
                      textAlign: "center",
                      backgroundColor: selectedVideos.length
                        ? "transparent"
                        : "#f8f9fa",
                      height: "200px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    {!selectedVideos.length && (
                      <>
                        <p>Add Video drag or drop here...</p>
                      </>
                    )}
                    <TextField
                      type="file"
                      accept="video/*"
                      onChange={handleVideoUpload}
                      sx={{ display: "none" }}
                      id="video-upload"
                      multiple
                    />
                    <label
                      htmlFor="video-upload"
                      style={{
                        position: "relative",
                        top: "20px",
                        cursor: "pointer",
                      }}
                    >
                      <img src={choosevideo} alt="" style={{ width: "40%" }} />
                    </label>
                    <div
                      className="video-preview-container"
                      style={{
                        marginTop: "16px",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                        width: "100%",
                        height: "auto",
                      }}
                    >
                      {selectedVideos.map(({ videoUrl }, index) => (
                        <div
                          key={index}
                          style={{
                            position: "relative",
                            display: "inline-block",
                            width: "100%",
                            height: "200px",
                            overflow: "hidden",
                          }}
                        >
                          <video
                            src={videoUrl}
                            controls
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              borderRadius: "10px",
                              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                              cursor: "pointer",
                            }}
                            onClick={() => handleFullscreenOpen(videoUrl)}
                          />
                          <Button
                            onClick={() => handleEditVideo(videoUrl)}
                            sx={{
                              position: "absolute",
                              top: "0px",
                              left: "-38px",
                              minWidth: "24px",
                              minHeight: "24px",
                              borderRadius: "50%",
                              padding: "0",
                              background: "none",
                            }}
                          >
                            <img
                              src={editimg}
                              alt=""
                              style={{ width: "25%" }}
                            />
                          </Button>
                          <Button
                            onClick={() => handleRemoveVideo(videoUrl)}
                            sx={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              minWidth: "24px",
                              minHeight: "24px",
                              borderRadius: "50%",
                              padding: "0",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.7)",
                              },
                            }}
                          >
                            &times;
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              <div className="modal-create-post-options">
                <div className="modal-post-icon">
                  <Button
                    variant="text"
                    className="post-option"
                    sx={{ height: "24px", mr: 2 }}
                    onClick={() => contentSelector(contentEnum.IMAGE)}
                  >
                    {" "}
                    <img
                      src={gallery}
                      alt="Gallery"
                      className="post-option-icon"
                    />
                  </Button>
                  <Button
                    variant="text"
                    className="post-option"
                    sx={{ height: "24px", mr: 2 }}
                    onClick={() => contentSelector(contentEnum.VIDEO)}
                  >
                    {" "}
                    <img
                      src={videos}
                      alt="Video"
                      className="post-option-icon"
                    />
                  </Button>
                  <Button
                    variant="text"
                    className="post-option"
                    sx={{ height: "24px", mr: 2 }}
                    onClick={() => contentSelector(contentEnum.PODCAST)}
                  >
                    {" "}
                    <img
                      src={podcost}
                      alt="Podcast"
                      className="post-option-icon"
                    />
                  </Button>
                  <Button
                    variant="text"
                    className="post-option"
                    sx={{ height: "24px", mr: 2 }}
                    onClick={() => contentSelector(contentEnum.POLL)}
                  >
                    {" "}
                    <img src={poll} alt="Poll" className="post-option-icon" />
                  </Button>
                </div>
                <div className="model-post-btn">
                  <Button
                    variant="contained"
                    disabled={postContent.length > 500}
                    onClick={handleSavePost}
                    sx={{
                      width: "89px",
                      height: "36px",
                      fontSize: "14px",
                      fontFamily: "Poppins, Regular",
                      textTransform: "none",
                      background:
                        "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                      color: "#fff",
                      borderRadius: "30px",
                      "&:hover": {
                        background:
                          "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                        opacity: 0.9,
                      },
                    }}
                  >
                    Post
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
