import axios from "axios";
import { errorHandler } from "./error-handler";

export async function networkRequest(
  method = "GET",
  url,
  body,
  headers = {},
  queryParams = {},
  urlParams = {}
) {
  try {
    if (urlParams && Object.keys(urlParams).length > 0) {
      Object.keys(urlParams).forEach((key) => {
        url = url.replace(`:${key}`, encodeURIComponent(urlParams[key]));
      });
    }
    const formData = new FormData();

    if (method !== "GET" && method !== "HEAD" && !body instanceof FormData) {
      for (const key in body) {
        formData.append(key, body[key]);
      }
    }

    const token = localStorage.getItem("token");

    if (!headers?.authorization && token) {
      headers.authorization = token;
    }

    const config = {
      method: method,
      url,
      headers: headers,
      params: queryParams,
    };

    if (method !== "GET" && method !== "HEAD") {
      config.data = !body instanceof FormData ? formData : body;
      if (!config.headers) {
        config.headers = {};
      }
      config.headers["Content-Type"] = "multipart/form-data";
    }

    const response = await axios(config);

    return response?.data;
  } catch (error) {
    errorHandler(error);
    console.error(
      "Axios error:",
      error.response ? error.response.data : error.message
    );
    // throw error;
  }
}
