import React from "react";
import activatree from "../img/activatree.png";
import '../css/navbar.css';

export default function LogoImg() {
  return (
    <>
      <div className="logo-img mb-0">
        <a href="/">
          <img src={activatree} alt="log" style={{ width: '100%', height: '100%'}} />
        </a>
      </div>
    </>
  );
}
