import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css';
import '../css/blog.css';
import { Link } from 'react-router-dom';
import NavBar from './navbar';
import Footer from './footer';
import aboutline from '../img/aboutline.png';
import revolutionizing from '../img/revolutionizing.png';
import impactful from '../img/impactful.png';
import rightarrow from '../img/rightarrow.png';
import titlearrow from '../img/titlearrow.png';

export default function Blog() {
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <NavBar />
                    <div className="title-blog">
                        <p>Home</p><img src={titlearrow} alt="Title Arrow" /><p>Blog</p>
                    </div>
                    <div className="blog-summury">
                        <h1>Blog</h1>
                        <img src={aboutline} alt="About Line" />
                        <p>As technology continues to reshape our digital experiences, it’s essential to stay informed and adapt. In this blog, we dive into the latest trends, tools, and strategies that are redefining how we create and interact online.    </p>
                    </div>

                    <div className="row mt-5 py-5">
                        <div className="col-lg-6">
                            <div className="revolutionizing">
                                <img src={revolutionizing} alt="Revolutionizing" style={{ width: '100%'}}/>
                                <h3>Revolutionizing Social Media Collaboration: The Activatree Way</h3>
                                <p>Social media has become an essential part of daily life, a tool that allows us to communicate, share, and create. But as the world of digital content continues to grow, so do the challenges of collaboration...</p>
                                <Link to="/revolutionizing" style={{ fontSize: '16px', fontFamily: 'Poppins, Semibold', fontWeight: '500', textDecoration: 'none', color: " black", cursor: 'pointer'}}>Read More <img src={rightarrow} alt="Read More" style={{ width: '3%'}}/></Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="impactful">
                                <img src={impactful} alt="Impactful" style={{ width: '100%'}}/>
                                <h3>Impactful Social Media Collaboration: The Activatree Way</h3>
                                <p>Social media has become an essential part of daily life, a tool that allows us to communicate, share, and create. But as the world of digital content continues to grow, so do the challenges of collaboration...</p>
                                <Link to="/impactful" style={{ fontSize: '16px', fontFamily: 'Poppins, Semibold', fontWeight: '500', textDecoration: 'none', color: " black", cursor: 'pointer'}}>Read More <img src={rightarrow} alt="Read More" style={{ width: '3%'}}/></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
