// import logo from './logo.svg';
// import './App.css';
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginForm from "./components/loginform";
import "bootstrap/dist/css/bootstrap.min.css";
import SignUpForm from "./components/signupform";
import IndividualSignupForm from "./components/individualsignup";
import BusinessSignupForm from "./components/businesssignup";
import SocialMedias from "./components/socials";
import HomePage from "./components/homepage";
import SocialMedia from "./components/socialmedia";
import ResetPassword from "./components/resetpassword";
import ResetNewPassword from "./components/newpassword";
import InterestsForm from "./components/your-intereste";
import ProfilePage from "./components/individualsocial";
import BusinessProfilePage from "./components/businesssocial";
import AboutUs from "./components/aboutus";
import HelpCenter from "./components/helpcenter";
import WorkSpace from "./components/workspace";
import MarketPlace from "./components/marketplace";
import NewsChannel from "./components/newschannel";
import WhatsNew from "./components/whatsnew";
import Blog from "./components/blog";
import Revolutionizing from "./components/revolutionizing";
import ImpactFul from "./components/impactful";
import Investors from "./components/investors";
import TermsOfService from "./components/termsofservice";
import PrivacyPolicy from "./components/privacypolicy";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserProfileProvider } from "./context/profile.contex";
import Routers from "./router";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  return (
    <Router>
      <GoogleOAuthProvider clientId="274136206982-naj76ba4l49nqieh60ce0o4lkep704n3.apps.googleusercontent.com">
        <UserProfileProvider>
          <Routers />
          <ToastContainer
            position="top-right"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </UserProfileProvider>
      </GoogleOAuthProvider>
    </Router>
  );
}

export default App;
