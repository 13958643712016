import { AxiosError } from "axios";
import { toast } from "react-toastify";

// Helper function to capitalize the first letter of each word
function capitalizeWords(message) {
  if (!message) return message;
  return message
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function errorHandler(error) {
  if (error instanceof AxiosError) {
    let message;

    if (error?.response?.data?.error) {
      message = error?.response?.data?.error;
    }

    if (error?.response?.data?.message) {
      message = error?.response?.data?.message;
    }

    if (error.status === 500) {
      message = "Internal Server Error";
    }
    // Capitalize the first letter of each word in the message
    message = capitalizeWords(message);

    const str = message;
    const regex = /"sociallinks\[\d+\]" (.+)/;
    const match = str?.match(regex);

    console.log("match?.[1]", match);

    if (match?.[1] === "Must Be A Valid Uri") {
      message = match[1];
    }

    message = capitalizeWords(message);

    toast(message);
    // switch (error.status) {
    //   case 404:
    //     toast(message);
    //     break;
    //   case 400:
    //     toast(message);
    //     break;
    //   case 422:
    //     toast(message);
    //     break;
    //   case 409:
    //     toast(message);
    //     break;
    //   case 401:
    //     toast(message);
    //     break;
    //   case 500:
    //     toast(message);
    //     break;
    //   default:
    //     break;
    // }
  }
}
