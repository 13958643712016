import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../config/api-urls";
import { networkRequest } from "../../utils/network-request";
import { errorHandler } from "../../utils/error-handler";

export const checkUserProfile = createAsyncThunk(
  "session/checkUserProfile",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { user } = getState().session;

      const token = localStorage.getItem("token");

      // if (!token) {
      //   return (window.location.href = "/loginform");
      // }

      if (!user) {
        try {
          const response = await networkRequest(
            "post",
            apiUrl.USER_PROFILE,
            {},
            { authorization: token }
          );
          return response.data;
        } catch (error) {
          if (error.response.data.error === "UnauthorizedError") {
            localStorage.removeItem("token");
            return (window.location.href = "/loginform");
          }
        }
      }
    } catch (error) {
      // window.location.href = "/";
      rejectWithValue(error.response?.data || "Failed to fetch profile");
      errorHandler(error);
    }
  }
);
