const userUrl =
  "https://api.user.activatree.com" || "http://192.168.0.118:8080";
const postUrl =
  "https://api.post.activatree.com" || "http://192.168.0.118:8081";

export const apiUrl = {
  SIGN_UP_OTP: `${userUrl}/api/user/otp/sign-up`,
  RESET_PASSWORD_OTP: `${userUrl}/api/user/otp/password`,
  RESET_PASSWORD_OTP_VERIFY: `${userUrl}/api/user/otp/verify`,
  RESET_PASSWORD: `${userUrl}/api/user/reset-password`,
  SIGN_UP: `${userUrl}/api/user/sign-up`,
  LOGIN: `${userUrl}/api/user/login`,
  LOGIN_GOOGLE_CALLBACK: `${userUrl}/api/user/google/callback`,
  GET_SUGGESTED_USERS: `${userUrl}/api/user/suggested`,
  ADD_PROFILE_PICTURE: `${userUrl}/api/user/profile-picture`,
  OTP_VERIFY: `${userUrl}/api/user/otp/verify`,
  USER_PROFILE: `${userUrl}/api/user`,
  USER_FOLLOW: `${userUrl}/api/user/follow`,
  USER_UN_FOLLOW: `${userUrl}/api/user/follow`,
  USER_BANNER_PICTURE: `${userUrl}/api/user/banner-picture`,
  GET_POSTS: `${postUrl}/api/post`,
  CREATE_POST: `${postUrl}/api/post`,
  UPDATE_POST: `${postUrl}/api/post`,
  DELETE_POST: `${postUrl}/api/post`,
  CREATE_LIKE: `${postUrl}/api/like`,
  DELETE_LIKE: `${postUrl}/api/like`,
  GET_COMMENTS: `${postUrl}/api/comment`,
  CREATE_COMMENT: `${postUrl}/api/comment`,
  DELETE_COMMENT: `${postUrl}/api/comment`,
};
