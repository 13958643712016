import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css';
import NavBar from './navbar';
import Footer from './footer';
import '../css/newschannel.css';
import commingsoon from '../img/commingsoon.png';

export default function HelpCenter() {
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <NavBar />
                    <div className="helpcenter-content mt-5 py-5">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="helpcenter-title">
                                    <h1>News Channel is on its way.</h1>
                                    <p>We’re working hard to bring you something amazing. This page is currently under development, but it won’t be long before it’s ready!</p>
                                    <p>Stay tuned for upcoming updates and exciting features!</p>
                                    <p><span>Thank you for your patience and support!</span></p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="commingsoon-img">
                                    <img src={commingsoon} alt="Commingsoon" style={{ width: '100%'}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
