import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css';
import '../css/whatsnew.css';
import { Box, Typography, Button, TextField, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import NavBar from './navbar';
// import { useNavigate } from 'react-router-dom';
import Footer from './footer';
import aboutline from '../img/aboutline.png';
import point from '../img/point.png';
import titlearrow from '../img/titlearrow.png';

const StyledListItem = styled(ListItem)(({ theme }) => ({
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
}));

export default function WhatsNew() {

    const features = [
        {
            title: 'Create Your Profile:',
            description:
                "Work with multiple creators in real-time on a single post. Expand your content's potential through collaboration and innovation.",
        },
        {
            title: 'Sign-Up is Live!:',
            description:
                'It’s easier than ever to join the Activatree community. Start today and build your presence.',
        },
        {
            title: 'Social Media Page Setup:',
            description: 'Customize your own social media page to showcase your brand or personal profile.',
        },
        {
            title: 'Text, Photo, and Video Posts:',
            description: 'Share updates, images, and videos easily with your audience and engage with your community.',
        },
        {
            title: 'Bookmark Content:',
            description: 'Save posts for later, so you can revisit the content that matters most to you.',
        },
        {
            title: 'Follow & Follower System:',
            description:
                'Follow others to stay updated with their posts, and have your friends or audience follow you in return.',
        },
    ];

    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <NavBar />
                    <div className="title-whatsnew">
                        <p>Home</p><img src={titlearrow} alt="Title Arrow" /><p>What's New</p>
                    </div>
                    <div className="whats-summury">
                        <h1>What's New</h1>
                        <img src={aboutline} alt="About Line" />
                        <p>We're excited to announce the launch of Activatree! Here's what's available for you.</p>
                    </div>
                    <Box sx={{ marginTop: '5pc' }}>
                        <Typography variant="h4" component="h2" gutterBottom sx={{ fontSize: '45px', fontFamily: 'Poppins, Bold', fontWeight: '700' }}>
                            For Social Media:
                        </Typography>

                        <List>
                            {features.map((feature, index) => (
                                <StyledListItem key={index}>
                                    <ListItemIcon>
                                        <img src={point} alt="Pointer icon" style={{ width: '24px', height: '24px' }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h6" component="h3" fontWeight="bold" margin={0} sx={{ fontSize: '20px', fontFamily: 'Poppins, Bold', margin: '0px' }}>
                                                {feature.title}
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant="body2" color="textSecondary" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', color: '#63676A' }}>
                                                {feature.description}
                                            </Typography>
                                        } sx={{ marginTop: '-5px' }}
                                    />
                                </StyledListItem>
                            ))}
                        </List>

                        <Typography variant="body1" color="textSecondary" align="flex-start" fontWeight="bold" sx={{ marginTop: '2rem', fontSize: '20px', fontFamily: 'Poppins, Bold', color: 'black' }}>
                            This content focuses on the foundational features while encouraging users to join and engage with the platform.
                        </Typography>

                        <div className="row mt-5 mb-5">
                            <div className="col-lg-6">
                                <Box sx={{ textAlign: 'justify' }}>
                                    <Typography variant="h6" sx={{ fontSize: '26px', fontFamily: 'Poppins, Medium', fontWeight: '500', lineHeight: '1.2' }}>
                                        Join us as we grow and continue to add exciting new features to enhance your experience!
                                    </Typography>
                                </Box>
                            </div>

                            <div className="col-lg-6">
                                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Your email"
                                        sx={{
                                            mb: 2,
                                            '& .MuiOutlinedInput-root': { borderRadius: '30px', width: '300px', height:  '47px', border: '1px solid #d3d3d3', padding: '1px 14px'  }
                                          }}
                                    />
                                    <Button variant="contained" sx={{ height:  '47px',  borderRadius: '30px', fontFamily: 'poppins', fontWeight: '600', fontSize: '14px', background: 'linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)', textTransform: 'none'  }}>
                                        Get in touch
                                    </Button>
                                </Box>
                            </div>
                        </div>
                    </Box>
                </div>
            </div >
            <Footer />
        </>
    )
}
