import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css';
import '../css/invetors.css';
import NavBar from './navbar';
// import { useNavigate } from 'react-router-dom';
import Footer from './footer';
import aboutline from '../img/aboutline.png';
import one from '../img/one.png';
import three from '../img/three.png';
import two from '../img/two.png';
import four from '../img/four.png';
import five from '../img/five.png';
import email from '../img/email.png';
import investarrow from '../img/investarrow.png';
import money from '../img/money.png';
import titlearrow from '../img/titlearrow.png';

export default function Investors() {
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <NavBar />
                    <div className="title-investor">
                        <p>Home</p><img src={titlearrow} alt="Title Arrow" /><p>Investors</p>
                    </div>
                    <div className="investors-summury">
                        <h1>Dear Potential Investors</h1>
                        <img src={aboutline} alt="About Line" />
                        <p>Discover the opportunity to invest in Activatree, a pioneering social media platform reshaping the way people connect, collaborate, and create. Our mission is to foster innovation in social networking with features that empower users and businesses alike.</p>
                    </div>

                    <div className="invest mt-5">
                        <div className="invest-activatree" style={{ width: '620px'}}>
                            <h1>Why Invest in <span style={{ fontSize: '45px', fontFamily: 'Poppins, Mixed', fontWeight: 400, color: '#9F00AA' }}>Activatree</span></h1>
                            <p>Activatree empowers creators and professionals to collaborate, monetize, and grow their networks seamlessly. With cutting-edge tools and AI-driven personalization, it's the platform where creativity meets opportunity.</p>
                        </div>

                        <div className="innovatives mt-4 mb-5">
                            <img src={one} alt="Platform" />
                            <div className="innovative py-3">
                                <h2>Innovative Platform</h2>
                                <p>Activatree is revolutionizing the social media landscape by combining immersive experiences, AI-driven content creation, and eco-conscious digital innovation.</p>
                            </div>
                        </div>

                        <div className="innovatives mt-4 mb-5">
                            <img src={two} alt="High Growth Trajectory" />
                            <div className="innovative py-3">
                                <h2>High Growth Trajectory</h2>
                                <p>With a fast-growing global community and a unique blend of features, Activatree is positioned to scale rapidly and attract a wide user base.</p>
                            </div>
                        </div>

                        <div className="innovatives mt-4 mb-5">
                            <img src={three} alt="Strong Leadership" />
                            <div className="innovative py-3">
                                <h2>Strong Leadership</h2>
                                <p>Led by a team with a proven track record in tech and social media development.</p>
                            </div>
                        </div>

                        <div className="innovatives mt-4 mb-5">
                            <img src={four} alt="Tech-Driven Impact" />
                            <div className="innovative py-3">
                                <h2>Tech-Driven Impact</h2>
                                <p>From blockchain integration to AI-powered tools, our platform is built on a foundation of cutting-edge technologies designed for the future.</p>
                            </div>
                        </div>

                        <div className="innovatives mt-4 mb-5">
                            <img src={five} alt="Sustainable" />
                            <div className="innovative py-3">
                                <h2>Sustainable and Ethical Growth</h2>
                                <p>We are committed to building a platform that aligns with environmental and ethical standards, promoting a sustainable digital ecosystem.</p>
                            </div>
                        </div>
                    </div>

                    <div className="invest-engage">
                        <div className="invest" style={{ width: '620px' }}>
                            <h1>Investor <span style={{ fontSize: '45px', fontFamily: 'Poppins, Mixed', fontWeight: 400, color: '#9F00AA' }}>Engagement</span></h1>
                            <p>At Activatree, we offer strategic investment opportunities in a growing social media platform that blends cutting-edge technology with a sustainable vision. Partner with us to help shape the future of digital networking and innovation.</p>
                        </div>
                        <div className="container my-5">
                            <div className="row">
                                <div className="col-lg-5 mb-4">
                                    <div className="investment-opportunities p-4" style={{ backgroundColor: '#8400A3', borderRadius: '15px' }}>
                                        <h2 className="text-white">Investment Opportunities</h2>
                                        <p className="text-white">
                                            Interested in exploring investment opportunities with Activatree?
                                        </p>
                                        <p className="text-white">
                                            We offer in-depth insights into our growth strategy, financial performance, and long-term vision.
                                        </p>
                                        <p className="text-white">
                                            For more information or to schedule a meeting, please reach out to our investor relations team at
                                        </p>
                                        <div className="email-sections">
                                            <p className="email-section">
                                                <img src={email} alt="Email Icon" style={{ width: '25px', marginRight: '10px' }} />
                                                <span className="text-white">investors@activatree.com</span>
                                            </p>
                                            <div className="text-white">
                                                <img src={investarrow} alt="Arrow Icon" style={{ width: '27%', position: 'relative', top: '-1pc', left: '1pc' }} />
                                            </div>
                                        </div>
                                        <div className="text-white">
                                            <img src={money} alt="Money Icon" style={{ width: '23%', position: 'relative', left: '-2pc', top: '2pc' }} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-7 mb-4">
                                    <div className="contact-form p-4" style={{ backgroundColor: '#f5f5f5', borderRadius: '15px' }}>
                                        <h2>Join us in shaping the future of social media.</h2>
                                        <form>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <input type="text" className="form-control" placeholder="Your name" />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <input type="text" className="form-control" placeholder="Your company name" />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <input type="text" className="form-control" placeholder="Your phone number" />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <input type="email" className="form-control" placeholder="Your email" />
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <textarea className="form-control" placeholder="Your message" rows="4"></textarea>
                                                </div>
                                                <div className="col-12 d-flex flex-row-reverse">
                                                    <button type="submit" className="btn btn-primary" style={{ background: 'linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)', borderWidth: '0px' }}>
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    )
}
