import React, { useEffect, useRef, useState } from "react";
import { Button, TextField, Typography, Link, Box } from "@mui/material";
import picture from "../img/picture.png";
import uploadpicture from "../img/uploadpicture.png";
import { useForm } from "react-hook-form";
import "../css/loginform.css";
import signup from "../img/signup.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import LogoImg from "./logo";
import { useUserProfile } from "../context/profile.contex";
import { networkRequest } from "../utils/network-request";
import { apiUrl } from "../config/api-urls";
import { usersEnum } from "../config/enum";

export default function BusinessSignupForm() {
  const { handleSubmit, register } = useForm();
  const [operatingHours, setOperatingHours] = useState("");
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);
  const [accountType, setAccountType] = useState("usersEnum.BUSINESS");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [bioData, setBioData] = useState("");
  const { user, isLoading, error, checkUserProfile, setUserProfile } =
    useUserProfile();

  

  const handleImageUpload = (event) => {
    const files = event.target.files;
    setSelectedImage(files[0]);
  };

  const triggerFileSelect = () => {
    fileInputRef?.current?.click();
  };

  function handleStateChange(e) {
    const value = e.target.value || "";
    switch (e.target.name) {
      case "phoneNumber":
        setPhoneNumber(value);
        break;
      case "address":
        setAddress(value);
        break;
      case "bioData":
        setBioData(value);
        break;
    }
  }

  async function changeProfile() {
    if (!selectedImage) return;
    const form = new FormData();
    form.append("profilePicture", selectedImage);
    const data = await networkRequest(
      "patch",
      apiUrl.ADD_PROFILE_PICTURE,
      form
    );
    console.log("data.user", data.data.user);
    setUserProfile(data.data.user);
  }

  useEffect(() => {
    changeProfile();
  }, [selectedImage]);

  const onSubmit = async (data) => {
    try {
      const response = await networkRequest("patch", apiUrl.USER_PROFILE, {
        ...data,
        userId: user.userId,
        type: usersEnum.BUSINESS,
        email: user.email,
      });
      if (response) {
        setUserProfile(response.data);
        navigate("/socials");
      }
    } catch (error) {
      console.error("Error signing up:", error);
    }
  };

  return (
    <>
      <div className="container">
        <LogoImg />
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="form">
              <Box sx={{ justifyContent: "center", alignItems: "center" }}>
                <div className="title mb-5 py-5">
                  <Typography
                    variant="h1"
                    component="h1"
                    sx={{
                      fontWeight: "700",
                      fontSize: "45px",
                      fontFamily: "poppins, semiBold",
                    }}
                  >
                    Business Sign Up
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ mb: 4, fontFamily: "poppins", fontSize: "14px" }}
                  >
                    Other information
                  </Typography>
                </div>

                {/* Picture Upload */}
                <img
                  src={user?.profilePicture}
                  alt="google"
                  width={50}
                  height={50}
                />
                <Button
                  type="file"
                  variant="contained"
                  color="primary"
                  onClick={triggerFileSelect}
                  sx={{ ml: 3 }}
                  id="file-upload"
                  style={{
                    borderRadius: "30px",
                    height: "47px",
                    fontFamily: "poppins",
                    fontWeight: "400",
                    fontSize: "14px",
                    background:
                      "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                  }}
                  onChange={handleStateChange}
                >
                  <img
                    src={uploadpicture}
                    alt="uploadpicture"
                    width={30}
                    height={30}
                    style={{ padding: 5 }}
                  />
                  Upload a Picture
                </Button>

                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                  multiple={false}
                />

                {/* Information */}
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    width: "450px",
                    marginTop: "50px",
                  }}
                >
                  {/* Phone Number Field */}
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    {...register("phoneNumber")}
                    InputProps={{
                      style: {
                        borderRadius: "30px",
                        backgroundColor: "#F9F9F9",
                        "& .MuiInputLabel-asterisk": { color: "red" },
                      },
                    }}
                  />

                  {/* Address Field */}
                  <TextField
                    label="Address"
                    variant="outlined"
                    {...register("address")}
                    InputProps={{
                      style: {
                        borderRadius: "30px",
                        backgroundColor: "#F9F9F9",
                      },
                    }}
                  />

                  {/* About Company Field */}
                  <TextField
                    label="About Company..."
                    variant="outlined"
                    {...register("bio")}
                    InputProps={{
                      style: {
                        borderRadius: "30px",
                        backgroundColor: "#F9F9F9",
                        height: "60px",
                      },
                    }}
                  />

                  {/* Operating Hours Field */}
                  <TextField
                    label="Operating Hours"
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "30px",
                        backgroundColor: "#F9F9F9",
                      },
                    }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 3,
                      mt: 1,
                      flexDirection: "row-reverse",
                    }}
                  >
                    <Link
                      onClick={() => navigate("/socials")}
                      sx={{
                        fontSize: 14,
                        fontFamily: "poppins",
                        fontWeight: 600,
                        color: "#9A00A9",
                        textDecoration: "none",
                      }}
                    >
                      Skip for Now
                    </Link>
                  </Box>

                  {/* Submit Button */}
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mb: 2 }}
                    style={{
                      borderRadius: "30px",
                      height: "47px",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      background:
                        "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                    }}
                    type="submit"
                  >
                    Next
                  </Button>
                </form>
              </Box>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="form-img ">
              <img src={signup} alt="signup" style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
