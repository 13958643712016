import { createSlice } from "@reduxjs/toolkit";
import { checkUserProfile } from "../thunk/session";

export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    user: null,
    isLoading: true,
  },
  reducers: {
    addSession: (state, action) => {
      state.user = action.payload;
      state.user.token = action?.payload?.sessions?.[0]?.token;
    },
    removeSession: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkUserProfile.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(checkUserProfile.fulfilled, (state, action) => {
        if (action.payload) {
          state.user = action.payload;
        }
        state.status = "succeeded";
        state.isAuthenticated = true;
        state.isLoading = false;
      })
      .addCase(checkUserProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.isAuthenticated = false;
        // state.isLoading = false;
      });
  },
});

export const { removeSession, addSession } = sessionSlice.actions;

export default sessionSlice.reducer;
