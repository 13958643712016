import React, { useRef, useState } from "react";
import {
  Avatar,
  Button,
  IconButton,
  Typography,
  Link,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  Box,
  InputAdornment,
  Menu,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MoreHoriz, PlaceRounded } from "@mui/icons-material";
import "../css/profilepage.css";
import NavBar from "./navbar";
import LeftSide from "./leftside";
import RightSide from "./rightside";
import Container from "react-bootstrap/Container";
import camera from "../img/camera.png";
import calander from "../img/calander.png";
import editprofile from "../img/editprofile.png";
import add from "../img/add.png";
import facebook from "../img/facebook.png";
import google1 from "../img/google1.png";
import likes from "../img/likes.png";
import comment from "../img/comment.png";
import report from "../img/report.png";
import upload from "../img/upload.png";
import save from "../img/save.png";
import locations from "../img/location.png";
import cacke from "../img/cacke.png";
import createposts from "../img/createposts.png";
import arrow from "../img/arrow.png";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { networkRequest } from "../utils/network-request";
import { apiUrl } from "../config/api-urls";
import { useEffect } from "react";
import { usersEnum } from "../config/enum";
import likess from "../img/likess.png";
import Zoom from "react-medium-image-zoom";
import CommentsModal from "./comments-modal";
import { useForm } from "react-hook-form";
import { useUserProfile } from "../context/profile.contex";
import website from "../img/website.png";
import whatsapp from "../img/whatsapp.png";
import instagram from "../img/instagram.png";
import x from "../img/x.png";
import youtube from "../img/youtube.png";
import telegram from "../img/telegram.png";
import rumble from "../img/rumble.png";
import snapchat from "../img/snapchat.png";
import truthsocial from "../img/truthsocial.png";
import linkedin from "../img/linkdin.png";
import tiktok from "../img/tiktok.png";
import threads from "../img/threads.png";
import parler from "../img/Parler.png";
import reddit from "../img/reddit.png";
import discord from "../img/Discord.png";
import gettr from "../img/gettr.png";
import mastodon from "../img/Mastodon.png";
import pinterest from "../img/Pinterest.png";
import bereal from "../img/BeReal.png";
import dustbin from "../img/dustbin.png";
import edit from "../img/edit.png";
import linkdin from "../img/linkdin.png";
import { getValidAvatarUrl } from "../utils/avatar-url";
import moment from "moment-timezone";
import editepost from "../img/editpost.svg";
import archive from "../img/archive.svg";
import notification from "../img/notification.svg";
import pinprofile from "../img/pinprofile.svg";
import deletepost from "../img/deletepost.svg";
import EditPostModal from "./editpostmodal";
import { format } from "date-fns";
import phone from '../img/phone.png';

export default function ProfilePage() {
  const { handleSubmit, register, reset } = useForm();
  const [showMore, setShowMore] = useState(false);
  const [profile, setProfile] = useState({});
  const [createdAt, setCreatedAt] = useState(null);
  const [posts, setPosts] = useState([]);
  const [commentModal, setCommentModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [userName, setUserName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [genderType, setGenderType] = useState("");
  const [location, setLocation] = useState("");
  const { user, isLoading, error, checkUserProfile, setUserProfile } =
    useUserProfile();
  const { userId } = useParams();
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [socialLinks, setSocialLinks] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [modalPost, setModalPost] = useState(null);
  const [formLinks, setFormLinks] = useState({});

  const socialPlatforms = [
    { name: "website", label: "Website Link", icon: website },
    { name: "whatsapp", label: "WhatsApp", icon: whatsapp },
    { name: "facebook", label: "Facebook", icon: facebook },
    { name: "google", label: "Google", icon: google1 },
    { name: "instagram", label: "Instagram", icon: instagram },
    { name: "x", label: "X", icon: x },
    { name: "youtube", label: "Youtube", icon: youtube },
    { name: "telegram", label: "Telegram", icon: telegram },
    { name: "rumble", label: "Rumble", icon: rumble },
    { name: "snapchat", label: "Snapchat", icon: snapchat },
    { name: "truthsocial", label: "TruthSocial", icon: truthsocial },
    { name: "linkedin", label: "Linkedin", icon: linkedin },
    { name: "tiktok", label: "Tiktok", icon: tiktok },
    { name: "threads", label: "Threads", icon: threads },
    { name: "parler", label: "Parler", icon: parler },
    { name: "reddit", label: "Reddit", icon: reddit },
    { name: "discord", label: "Discord", icon: discord },
    { name: "gettr", label: "Gettr", icon: gettr },
    { name: "mastodon", label: "Mastodon", icon: mastodon },
    { name: "pinterest", label: "Pinterest", icon: pinterest },
    { name: "bereal", label: "Bereal", icon: bereal },
  ];

  const getLogo = (name) => {
    const obj = socialPlatforms.find((obj) => obj.name === name);
    if (obj) {
      return obj.icon;
    }
    return website;
  };




  const getIconFromUrl = (url) => {
    const domain = new URL(url).hostname.replace("www.", "").split(".")[0];

    const platform = socialPlatforms.find(
      (platform) => platform.name.toLowerCase() === domain.toLowerCase()
    );

    return platform ? platform.icon : "default-icon";
  };

  const getIcon = (type) => {
    switch (type) {
      case "facebook":
        return facebook;
      case "google":
        return google1;
      case "instagram":
        return instagram;
      case "linkedin":
        return linkedin;
      case "x":
        return x;
      case "youtube":
        return youtube;
      case "telegram":
        return telegram;
      case "rumble":
        return rumble;
      case "snapchat":
        return snapchat;
      case "truthsocial":
        return truthsocial;
      case "tiktok":
        return tiktok;
      case "threads":
        return threads;
      case "parler":
        return parler;
      case "reddit":
        return reddit;
      case "discord":
        return discord;
      case "gettr":
        return gettr;
      case "mastodon":
        return mastodon;
      case "pinterest":
        return pinterest;
      case "bereal":
        return bereal;
      // Add cases for each social platform icon
      // default:
      //     return defaultIcon;
    }
  };

  const handleShowMore = (postId) => {
    console.log("postId", postId);
    setShowMore((prevState) => ({
      ...prevState,
      [postId]: !prevState[postId],
    }));
  };

  async function getProfile() {
    const profile = await networkRequest("POST", apiUrl.USER_PROFILE, {
      userId,
    });

    setProfile(profile.data);
  }

  console.log("postssss", posts);

  async function getPosts() {
    const data = await networkRequest(
      "GET",
      apiUrl.GET_POSTS,
      {},
      {},
      { userId, ...(createdAt && { createdAt }) }
    );

    if (!data?.data?.posts) {
      return;
    }

    setPosts(data.data.posts);
    setCreatedAt(data.data.createdAt);
  }

  const toggleCommentBox = (postId) => {
    setCommentModal((prevPostId) => {
      if (!prevPostId && postId) {
        return postId;
      } else {
        return false;
      }
    });
  };

  async function manageLike(e, post) {
    if (!post?.isLiked) {
      await networkRequest("post", apiUrl.CREATE_LIKE, {
        postId: post?.postId,
      });

      setPosts((prev) => {
        const prePost = prev.find(
          (prePost) => prePost?.postId === post?.postId
        );
        prePost.likeCount += 1;
        prePost.isLiked = true;
        return structuredClone(prev);
      });
    } else {
      await networkRequest("delete", apiUrl.DELETE_LIKE, {
        postId: post?.postId,
      });

      setPosts((prev) => {
        const prePost = prev.find((prePost) => prePost.postId === post?.postId);
        prePost.likeCount -= 1;
        prePost.isLiked = false;
        return structuredClone(prev);
      });
    }
  }

  function onComment(postId) {
    setPosts((prev) => {
      const index = prev.findIndex((obj) => obj.postId === postId);
      const post = prev[index];
      if (!post) {
        return prev;
      }
      post.commentCount += 1;
      return structuredClone(prev);
    });
    navigate("/socialmedia");
  }

  useEffect(() => {
    getProfile();
    getPosts();
  }, [userId]);

  const handleEditProfileClick = () => {
    setOpenEditModal(true);
  };

  const handleClose = () => {
    setOpenEditModal(false);
  };

  const handleOnClick = () => {
    navigate("/socialmedia");
  };


  const onSubmit = async (data) => {
    console.log("data data data", dateOfBirth);
    try {
      const response = await networkRequest("patch", apiUrl.USER_PROFILE, {
        ...data,
        userId: user.userId,
        type: usersEnum.NORMAL,
        userName: userName,
        email: emailAddress,
        dateOfBirth: dateOfBirth,
        phoneNumber: phoneNumber,
        location: location,
        gender: genderType,
      });
      if (response) {
        setUserProfile(response.data);
        navigate("/socialmedia");
      }
      reset();
    } catch (error) {
      console.error("Error signing up:", error);
    }
  };

  const handleCoverPhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadImage(file, "coverPhoto");
    }
  };

  const uploadImage = async (file, type) => { };

  const handleImageUpload = (event) => {
    const files = event.target.files;
    setSelectedImage(files[0]);
  };

  const handleBannerUpload = (event) => {
    const files = event.target.files;
    setSelectedBanner(files[0]);
  };

  async function changeProfile() {
    if (!selectedImage) return;
    const form = new FormData();
    form.append("profilePicture", selectedImage);
    const data = await networkRequest(
      "patch",
      apiUrl.ADD_PROFILE_PICTURE,
      form
    );
    console.log("data.user", data?.data?.user);
    const d = structuredClone(data?.data?.user);
    setProfile(d);
    await getProfile();
  }

  useEffect(() => {
    changeProfile();
  }, [selectedImage]);

  useEffect(() => {
    console.log("Profile updated:", profile);
  }, [profile]);

  async function changeBanner() {
    if (!selectedBanner) return;
    const form = new FormData();
    form.append("bannerPicture", selectedBanner);
    const data = await networkRequest(
      "patch",
      apiUrl.USER_BANNER_PICTURE,
      form
    );
    setProfile(data?.data?.user);
  }

  useEffect(() => {
    changeProfile();
  }, [selectedImage]);

  useEffect(() => {
    changeBanner();
  }, [selectedBanner]);


  const [fields, setFields] = useState(
    socialPlatforms.reduce((acc, platform) => {
      acc[platform.name] = { isFocused: false, hasContent: false };
      return acc;
    }, {})
  );

  const handleFocus = (fieldName) => {
    setFields((prev) => ({
      ...prev,
      [fieldName]: { ...prev[fieldName], isFocused: true },
    }));
  };

  const handleBlur = (fieldName, event) => {
    setFields((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        isFocused: false,
        hasContent: Boolean(event.target.value),
      },
    }));
  };

  const handleAddSocialClick = () => {
    const existingLinks = {};
    socialLinks.forEach((link) => {
      existingLinks[link.platform] = link.linkUrl; 
    });

    console.log("Existing Links:", existingLinks);
    setFormLinks(existingLinks);
    setOpenAddModal(true);
  };

  function setLinkValue(name) {
    
    const link = profile?.socialLinks?.find((obj) => obj.logoUrl === name);
    
    if (!link) {
      return "";
    }

    return link?.linkUrl;
  }

  const onSave = async (data) => {
    console.log("Saving data:Saving data:Saving data: ", data); 
    try {
      const formData = new FormData();

      Object.entries(formLinks).forEach(([platform, linkUrl], index) => {
        formData.append(`logoUrls[${index}]`, socialLinks[index]?.logoUrl || platform || '');
        formData.append(`socialLinks[${index}]`, linkUrl || platform || '');
      });
      
      formData.append("type", usersEnum.NORMAL);
      formData.append("userId", user?.userId);

      const response = await networkRequest(
        "patch",
        apiUrl.USER_PROFILE,
        formData
      );

      if (response?.data) {
        setProfile(response.data);
        setUserProfile(response.data);
        setSocialLinks(socialLinks);
        setOpenAddModal(false);
        navigate("/socialmedia");
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("Error updating socials:", error);
    }
  };

  const handleClick = (event, post) => {
    setModalPost(post);
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onUpdate = (post, onUpdate) => { };

  const handleEditPostClick = (post) => {
    setEditModalOpen(true);
  };

  const handleSave = (updatedPost) => {
    setPosts((prev) => {
      const index = prev.findIndex(
        (item) => item.postId === updatedPost?.postId
      );
      prev[index] = updatedPost;
      return structuredClone(prev);
    });
    onClose();
    setEditModalOpen(false);
  };

  async function managePost(e) {
    console.log("delete test modalPost", modalPost);

    await networkRequest("delete", apiUrl.DELETE_POST, {
      postId: modalPost?.postId,
    });

    setAnchorEl(null);

    setPosts((prev) => {
      const postIndex = prev.findIndex(
        (prePost) => prePost?.postId === modalPost?.postId
      );
      delete prev[postIndex];
      return structuredClone(prev);
    });
  }

  return (
    <>
      <NavBar />
      <Container>
        <Grid container spacing={2}>
          {/* Left Sidebar */}
          <Grid item xs={3}>
            <LeftSide />
          </Grid>

          {/* Main Profile Section */}
          <Grid item xs={6}>
            <div className="back-page mb-2">
              <div className="back-page-title">
                <Button
                  onClick={handleOnClick}
                  sx={{ width: "22px", height: "22px" }}
                >
                  <img src={arrow} alt="Arrow" style={{ width: "100%" }} />
                </Button>
                <div className="page-title">
                  <h2>{profile?.fullName || profile?.businessName}</h2>
                  {/* <p>2 Posts</p> */}
                </div>
              </div>
            </div>

            <div className="profile-section">
              {/* Header and Profile Info */}
              <div className="profile-header">
                <div className="cover-photo">
                  <img
                    src={`${profile?.bannerPicture}?${new Date().getTime()}`}
                    alt=""
                    className="banner-picture"
                  />
                  {user?.userId === profile?.userId && (
                    <img
                      src={camera}
                      alt=""
                      onClick={() =>
                        document.getElementById("cover-photo-upload").click()
                      }
                      id="file-upload"
                      style={{
                        width: "28px",
                        position: "relative",
                        float: "right",
                        right: "15px",
                        top: "-40px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                  <input
                    type="file"
                    id="cover-photo-upload"
                    style={{ display: "none" }}
                    onChange={handleBannerUpload}
                  />
                </div>
                <Avatar
                  src={getValidAvatarUrl(profile?.profilePicture)}
                  className="profile-avatar"
                />
                {user?.userId === profile?.userId && (
                  <IconButton
                    className="edit-avatar-btn"
                    onClick={() =>
                      document.getElementById("profile-photo-upload").click()
                    }
                  >
                    <img src={camera} alt="" style={{ width: "100%" }} />
                  </IconButton>
                )}
                <input
                  type="file"
                  id="profile-photo-upload"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                  multiple={false}
                />
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Poppins, Regular",
                    fontWeight: "400",
                    position: "relative",
                    top: "55px",
                    left: "10px",
                  }}
                >
                  {profile?.fullName || profile?.businessName}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Poppins, Regular",
                    fontWeight: "400",
                    position: "relative",
                    top: "55px",
                    left: "10px",
                  }}
                >
                  @{profile?.userName}
                </Typography>
                {user?.userId === profile?.userId && (
                  <Button
                    variant="outlined"
                    className="editprofile-btn"
                    onClick={handleEditProfileClick}
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Poppins, Regular",
                      fontWeight: "500",
                      position: "relative",
                      top: "-20px",
                      float: "right",
                      right: "20px",
                    }}
                  >
                    <img
                      src={editprofile}
                      alt=""
                      style={{ width: "15%", margin: "5px" }}
                    />{" "}
                    Edit Profile
                  </Button>
                )}
                <div className="profile-details">
                  <div
                    className="profile-about mb-3"
                    style={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      // WebkitLineClamp: 2,
                    }}
                  >
                    {profile?.bio?.length > 180
                      ? `${profile.bio.substring(0, 180)}`
                      : profile?.bio}
                  </div>
                  <div className="profile-about-details">
                    {profile?.location && (
                      <Typography
                        variant="body2"
                        sx={{
                          display: "flex",
                          // width: "120px",
                          height: "20px",
                          fontSize: "14px",
                          fontFamily: "Poppins, Regular",
                          fontWeight: "400",
                          alignItems: "center",
                          // cursor: 'pointer',
                          justifyContent: "flex-start",
                          textDecoration: "none",
                          color: "#000",
                        }}
                      >
                        <img src={locations} alt="" style={{ width: "4%" }} />{" "}
                        {profile?.location}
                      </Typography>
                    )}
                    {/* {profile?.dateOfBirth && (
                      <Link
                        variant="body2"
                        sx={{
                          display: "flex",
                          width: "auto",
                          height: "20px",
                          fontSize: "14px",
                          fontFamily: "Poppins, Regular",
                          fontWeight: "400",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          cursor: "pointer",
                          textDecoration: "none",
                          color: "#000",
                        }}
                      >
                        <img src={cacke} alt="" style={{ width: "15%" }} />{" "}
                        {profile.dateOfBirth}
                      </Link>
                    )}
                    {profile?.createdAt && (
                      <Link
                        variant="body2"
                        sx={{
                          display: "flex",
                          width: "auto",
                          height: "20px",
                          fontSize: "14px",
                          fontFamily: "Poppins, Regular",
                          fontWeight: "400",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          cursor: "pointer",
                          textDecoration: "none",
                          color: "#000",
                        }}
                      >
                        <img src={calander} alt="" style={{ width: "10%" }} />{" "}
                        {new Date(profile?.createdAt).toDateString()}
                      </Link>
                    )} */}
                  </div>
                </div>
                {profile?.phoneNumber && (
                  <Typography
                    variant="body2"
                    className="contact-no">
                    <img src={phone} alt="" />
                    <p>{profile?.phoneNumber}</p>
                  </Typography>
                )}
                <div className="following-details">
                  <p>
                    <span style={{ fontWeight: "600" }}>
                      {profile?.followingCount}
                    </span>{" "}
                    Following
                  </p>
                  <p>
                    <span style={{ fontWeight: "600" }}>
                      {profile?.followersCount}
                    </span>{" "}
                    Followers
                  </p>
                  {profile?.dateOfBirth && (
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        width: "auto",
                        height: "20px",
                        fontSize: "14px",
                        fontFamily: "Poppins, Regular",
                        fontWeight: "400",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        // cursor: "pointer",
                        textDecoration: "none",
                        color: "#000",
                        marginTop: "20px",
                      }}
                    >
                      {/* <img src={cacke} alt="" style={{ width: "15%" }} />{" "} */}
                      <span
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          fontWeight: "500",
                          marginRight: "2px",
                        }}
                      >
                        DOB:
                      </span>
                      {/* {profile.dateOfBirth} */}
                      {profile?.dateOfBirth
                        ? format(new Date(profile.dateOfBirth), "dd/MM/yyyy")
                        : "N/A"}
                    </Typography>
                  )}
                  {profile?.createdAt && (
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        width: "auto",
                        height: "20px",
                        fontSize: "14px",
                        fontFamily: "Poppins, Regular",
                        fontWeight: "400",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        // cursor: "pointer",
                        textDecoration: "none",
                        color: "#000",
                        marginTop: "20px",
                      }}
                    >
                      {/* <img src={calander} alt="" style={{ width: "10%" }} />{" "} */}
                      <span
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          fontWeight: "500",
                          marginRight: "2px",
                        }}
                      >
                        Joined:
                      </span>
                      {new Date(profile?.createdAt).toDateString()}
                    </Typography>
                  )}
                </div>
              </div>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <form action="">
                  <Dialog
                    open={openEditModal}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogTitle>Edit Personal Information</DialogTitle>
                    <DialogContent>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="User Name"
                        onChange={(e) => {
                          setUserName(e.target.value);
                        }}
                        sx={{
                          mb: 2,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            height: "47px",
                            border: "1px solid #d3d3d3",
                            padding: "1px 14px",
                          },
                          "& .css-19qnlrw-MuiFormLabel-root-MuiInputLabel-root":
                          {
                            top: "-5px",
                          },
                        }}
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Email Address"
                        onChange={(e) => {
                          setEmailAddress(e.target.value);
                        }}
                        sx={{
                          mb: 2,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            height: "47px",
                            border: "1px solid #d3d3d3",
                            padding: "1px 14px",
                          },
                          "& .css-19qnlrw-MuiFormLabel-root-MuiInputLabel-root":
                          {
                            top: "-5px",
                          },
                        }}
                      />
                      <TextField
                        fullWidth
                        select
                        onChange={(e) => {
                          setGenderType(e.target.value);
                        }}
                        margin="normal"
                        label="Gender"
                        sx={{
                          mb: 2,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            height: "47px",
                            border: "1px solid #d3d3d3",
                            padding: "1px 14px",
                          },
                          "& .css-19qnlrw-MuiFormLabel-root-MuiInputLabel-root":
                          {
                            top: "-5px",
                          },
                        }}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </TextField>
                      <DatePicker
                        label="Date of Birth"
                        onChange={(date) => {
                          console.log("OnChange date", date);
                          setDateOfBirth(new Date(date));
                        }}
                        format="dd/MM/yyyy"
                        renderInput={(params) => (
                          <TextField fullWidth margin="normal" {...params} />
                        )}
                        sx={{
                          mb: 2,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            width: "545px",
                            height: "47px",
                            border: "1px solid #d3d3d3",
                            padding: "1px 14px",
                            top: "15px",
                          },
                          "& .css-19qnlrw-MuiFormLabel-root-MuiInputLabel-root":
                          {
                            top: "10px",
                          },
                        }}
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Phone Number"
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                        }}
                        sx={{
                          mb: 2,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            height: "47px",
                            border: "1px solid #d3d3d3",
                            padding: "1px 14px",
                            top: "15px",
                          },
                          "& .css-19qnlrw-MuiFormLabel-root-MuiInputLabel-root":
                          {
                            top: "10px",
                          },
                        }}
                      />
                      <TextField
                        fullWidth
                        onChange={(e) => {
                          setLocation(e.target.value);
                        }}
                        margin="normal"
                        label="Location"
                        sx={{
                          mb: 2,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            height: "47px",
                            border: "1px solid #d3d3d3",
                            padding: "1px 14px",
                            top: "15px",
                          },
                          "& .css-19qnlrw-MuiFormLabel-root-MuiInputLabel-root":
                          {
                            top: "10px",
                          },
                        }}
                      />
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        sx={{
                          mt: 2,
                          borderRadius: "30px",
                          background:
                            "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                        }}
                        onClick={handleSubmit(onSubmit)}
                      >
                        Save
                      </Button>
                    </DialogContent>
                  </Dialog>
                </form>
              </LocalizationProvider>
            </div>

            {/* Social Links */}
            <div className="social-links mt-3 p-3">
              <div className="social-title mb-3">
                <h3>Social Links</h3>
                {user?.userId === profile?.userId && (
                  <Button
                    variant="outlined"
                    className="add-btn"
                    onClick={handleAddSocialClick}
                  >
                    <img
                      src={add}
                      alt=""
                      style={{ width: "30%", margin: "5px" }}
                    />{" "}
                    Add
                  </Button>
                )}
              </div>
              <div className="row link-type">
                {profile?.socialLinks?.map((link, index) => (
                  <div className="col-lg-5" key={index}>
                    <div className="link">
                      <a
                        href={link?.linkUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          textDecoration: "none",
                          color: "#000",
                          fontSize: "13px",
                          fontFamily: "Poppins, Regular",
                          fontWeight: "500",
                          gap: "10px",
                        }}
                      >
                        <img
                          src={getLogo(link?.logoUrl)}
                          alt=""
                          className="icons"
                        />
                        <p style={{ margin: 0 }}>
                          {link?.linkUrl.length > 30
                            ? link?.linkUrl.slice(0, 30) + ""
                            : link?.linkUrl}
                        </p>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
              {/* HEllo */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Dialog
                  open={openAddModal}
                  onClose={() => setOpenAddModal(false)}
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogTitle>Edit Social Media</DialogTitle>
                  <DialogContent>
                    <form onSubmit={handleSubmit(onSave)}>
                      <Box
                        sx={{ justifyContent: "center", alignItems: "center" }}
                      >
                        {socialPlatforms.map(({ name, label, icon }) => (
                          <div
                            key={name}
                            className="d-flex mb-3"
                            style={{ alignItems: "center" }}
                          >
                            <TextField
                              label={
                                fields[name].isFocused ||
                                  fields[name].hasContent
                                  ? label
                                  : ""
                              }
                              variant="outlined"
                              defaultValue={setLinkValue(name)}
                              onFocus={() => handleFocus(name)}
                              onBlur={(event) => handleBlur(name, event)}
                              name={name}
                              onChange={(event) => setFormLinks((prev) => ({
                                ...prev,
                                [name]: event.target.value,
                              }))
                              }
                              InputProps={{
                                startAdornment: fields[name].isFocused || fields[name].hasContent
                                  ? null
                                  : (
                                    <InputAdornment position="start">
                                      <img
                                        src={icon}
                                        alt={`${label} Icon`}
                                        style={{
                                          width: 20,
                                          height: 20,
                                          marginRight: "8px",
                                        }}
                                      />
                                      <span>{label}</span>
                                    </InputAdornment>
                                  ),
                                style: {
                                  borderRadius: "30px",
                                  width: "450px",
                                  backgroundColor: "#F9F9F9",
                                },
                              }}
                              sx={{ transition: "all 0.3s ease" }}
                            />
                            <img
                              src={dustbin}
                              alt="Dustbin icon"
                              style={{
                                width: 30,
                                height: 30,
                                marginRight: "10px",
                              }}
                              onClick={() => {
                                setFormLinks((prev) => {
                                  const updatedLinks = { ...prev };
                                  delete updatedLinks[name];
                                  return updatedLinks;
                                });
                              }}
                            />
                          </div>
                        ))}
                      </Box>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        sx={{
                          mt: 2,
                          borderRadius: "30px",
                          background:
                            "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                        }}
                      >
                        Save
                      </Button>
                    </form>
                  </DialogContent>
                </Dialog>
              </LocalizationProvider>
            </div>

            {/* Posts Section */}
            {posts ? (
              posts.map((post) => {
                return (
                  <div className="posts-section">
                    <div className="post">
                      <div className="post-header mb-4">
                        <div className="post-author">
                          <div className="post-headers">
                            <Avatar src={post?.user?.profilePicture} />
                            <div className="post-author-info">
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: "Poppins, Medium",
                                  fontWeight: "500",
                                }}
                              >
                                {post?.user?.fullName}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "14px",
                                  fontFamily: "Poppins, Regular",
                                  color: "#63676A",
                                }}
                              >
                                @{post?.user?.userName}
                              </Typography>
                            </div>
                          </div>
                        </div>
                        <div className="post-btn">
                          {user?.userId === profile?.userId && (
                            <IconButton
                              sx={{
                                border: "2px solid black",
                                width: "26px",
                                height: "26px",
                                color: "black",
                              }}
                              onClick={(e) => handleClick(e, post)}
                            >
                              <MoreHoriz />
                            </IconButton>
                          )}
                        </div>
                      </div>
                      <Typography
                        variant="body2"
                        className="post-content"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins, Regular",
                          fontWeight: "normal",
                        }}
                      >
                        {post?.content?.length > 200 ? (
                          <>
                            {showMore[post?.postId] ? (
                              post?.content
                            ) : (
                              <>
                                {post?.content.substring(0, 200)}...
                                <Button
                                  variant="text"
                                  sx={{
                                    fontSize: "12px",
                                    padding: "0",
                                    minWidth: "unset",
                                  }}
                                  onClick={() => handleShowMore(post?.postId)}
                                >
                                  More
                                </Button>
                              </>
                            )}
                          </>
                        ) : (
                          post?.content
                        )}
                      </Typography>

                      {post?.media?.map((media) => {
                        if (media?.type === "photos") {
                          return (
                            <Zoom>
                              <div className="post-image">
                                <img src={media.url} alt="Vasa Ship" />
                              </div>
                            </Zoom>
                          );
                        }
                        if (media?.type === "video") {
                          return (
                            <div
                              className="post-image"
                              style={{ height: "500px", width: "600px" }}
                            >
                              <video controls controlsList="nodownload">
                                <source src={media.url} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          );
                        }
                      })}
                      <div
                        className="post-interactions d-flex align-items-center"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div
                          className="feed-icon d-flex align-item-center"
                          style={{ gap: "15px" }}
                        >
                          <IconButton
                            sx={{ display: "flex", alignItems: "center" }}
                            onClick={(e) => manageLike(e, post)}
                          >
                            {post?.isLiked ? (
                              <img
                                src={likess}
                                alt="Like"
                                style={{ width: "20px", height: "20px" }}
                              />
                            ) : (
                              <img
                                src={likes}
                                alt="Like"
                                style={{ width: "20px", height: "20px" }}
                              />
                            )}
                            <span
                              style={{
                                fontSize: "14px",
                                fontFamily: "Poppins, Regular",
                                fontWeight: "300",
                                position: "relative",
                                top: "3px",
                                left: "10px",
                              }}
                            >
                              {post?.likeCount}
                            </span>
                          </IconButton>

                          <IconButton
                            sx={{ display: "flex", alignItems: "center" }}
                            onClick={() => toggleCommentBox(post?.postId)}
                          >
                            <img
                              src={comment}
                              alt="Comment"
                              style={{
                                width: "20px",
                                height: "20px",
                                position: "relative",
                                top: "2px",
                              }}
                            />
                            <span
                              style={{
                                fontSize: "14px",
                                fontFamily: "Poppins, Regular",
                                fontWeight: "300",
                                position: "relative",
                                top: "3px",
                                left: "10px",
                              }}
                            >
                              {post?.commentCount}
                            </span>
                          </IconButton>

                          <IconButton
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={report}
                              alt="Report"
                              style={{
                                width: "20px",
                                height: "20px",
                                position: "relative",
                                top: "2px",
                              }}
                            />
                            <span
                              style={{
                                fontSize: "14px",
                                fontFamily: "Poppins, Regular",
                                fontWeight: "300",
                                position: "relative",
                                top: "3px",
                                left: "10px",
                              }}
                            >
                              45
                            </span>
                          </IconButton>
                        </div>

                        <div className="bookmarkrs d-flex align-items-center">
                          <IconButton
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={upload}
                              alt="Upload"
                              style={{
                                width: "20px",
                                height: "20px",
                                position: "relative",
                                top: "2px",
                              }}
                            />
                          </IconButton>
                          <IconButton
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={save}
                              alt="Save"
                              style={{
                                width: "20px",
                                height: "20px",
                                position: "relative",
                                top: "2px",
                              }}
                            />
                          </IconButton>
                        </div>
                      </div>
                      <div className="post-detail">
                        <p style={{ width: "130px", margin: "0px" }}>
                          {moment().diff(moment(post.createdAt), "hours") < 24
                            ? moment(post.createdAt)
                              .tz(moment.tz.guess())
                              .fromNow()
                            : moment(post.createdAt)
                              .tz(moment.tz.guess())
                              .format("Do MMMM YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="create-posts-section">
                <div className="create-posts">
                  <img src={createposts} alt="Creating Poosts" />
                  <h2>hasn't posted yet</h2>
                  <Link
                    sx={{
                      fontSize: "15px",
                      fontFamily: "Poppins, regular",
                      fontWeight: "400",
                      textDecoration: "none",
                      color: "#9A00A9",
                      cursor: "pointer",
                    }}
                  >
                    Create a Post
                  </Link>
                </div>
              </div>
            )}

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={onClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{
                "& .css-1tktgsa-MuiPaper-root-MuiPopover-paper-MuiMenu-paper": {
                  borderRadius: "10px",
                  boxShadow: "0px 0px 1px 1px lightgray",
                },
              }}
            >
              <MenuItem
                onClick={(e) => handleEditPostClick()}
                onClose={onclose}
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                }}
              >
                <img src={editepost} alt="" style={{ width: "25%" }} />
                Edit Post
              </MenuItem>
              {/* <MenuItem onClick={onClose} style={{ fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400', }}><img src={pinprofile} alt="" style={{ width: '25%' }} />Pin to your Profile</MenuItem>
              <MenuItem onClick={onClose} style={{ fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400', }}><img src={notification} alt="" style={{ width: '25%' }} />Turn of  notification for this post</MenuItem>
              <MenuItem onClick={onClose} style={{ fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400', }}><img src={archive} alt="" style={{ width: '25%' }} />Move to Archive</MenuItem> */}
              <MenuItem
                onClick={(e) => managePost(e)}
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                }}
              >
                <img src={deletepost} alt="" style={{ width: "25%" }} />
                Delete
              </MenuItem>
            </Menu>
          </Grid>

          {commentModal && (
            <CommentsModal
              onClose={toggleCommentBox}
              open={commentModal}
              postId={commentModal}
              onComment={onComment}
            />
          )}

          {isEditModalOpen && (
            <EditPostModal
              isOpen={isEditModalOpen}
              onClose={() => setEditModalOpen(false)}
              post={modalPost}
              onSave={handleSave}
            />
          )}

          {/* {editPost && (
            <EditPost
              onClose={() => handleEditPostClick(false)}
              open={editPost}
              postId={editPost}
            onSave={handleSave}
            />
          )} */}

          {/* Right Sidebar (Suggested for You) */}
          <Grid item xs={3}>
            <RightSide />
          </Grid>
        </Grid>
      </Container >
    </>
  );
}
