import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Typography,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ads from "../img/ads.png";
import jutus from "../img/jutus.png";
import { apiUrl } from "../config/api-urls";
import { networkRequest } from "../utils/network-request";
import { useUserProfile } from "../context/profile.contex";
import { useNavigate } from "react-router-dom";
import { getValidAvatarUrl } from "../utils/avatar-url";

export default function RightSide() {
  const [users, setUsers] = useState([]);

  const { user, isLoading, error, checkUserProfile, setUserProfile } =
    useUserProfile();
  const navigate = useNavigate();

  async function getUsers() {
    const data = await networkRequest(
      "GET",
      apiUrl.GET_SUGGESTED_USERS,
      {},
      {},
      { userId: user?.userId }
    );
    setUsers(data?.data?.users);
  }

  async function handdleProfileClick(userId) {
    navigate(`/individualsocial/${userId}`);
  }

  async function manageFollow(e, selectedUser) {
    if (!selectedUser?.isFollowed) {
      await networkRequest("post", apiUrl.USER_FOLLOW, {
        followedId: selectedUser?.userId,
      });

      setUsers((prev) => {
        for (let i = 0; i < prev.length; i++) {
          const user = prev[i];
          if (user?.userId === selectedUser?.userId) {
            user.isFollowed = true;
          }
        }
        return structuredClone(prev);
      });
    } else {
      await networkRequest("delete", apiUrl.USER_UN_FOLLOW, {
        followedId: user.userId,
      });

      setUsers((prev) => {
        for (let i = 0; i < prev.length; i++) {
          const user = prev[i];
          if (user?.userId === selectedUser?.userId) {
            user.isFollowed = false;
          }
        }
        return structuredClone(prev);
      });
    }
  }
  useEffect(() => {
    if (user) {
      getUsers();
    }
    // } else {
    // isMount.current = true;
    // }
  }, [user]);

  return (
    <>
      <div className="right-sidebar">
        <div className="search-bar mb-4">
          <TextField
            variant="outlined"
            placeholder="Search..."
            className="custom-search-bar"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="sidebar-image mb-4">
          <img src={ads} alt="Sidebar-Image" className="sidebar-img" />
        </div>

        <div className="suggestions-section">
          <Typography variant="h6">Suggested for you</Typography>

          {users?.map((user) => {
            return (
              <div className="suggested-user mb-3">
                <div
                  className="user-detail"
                  style={{ cursor: "pointer" }}
                  onClick={() => handdleProfileClick(user?.userId)}
                >
                  <Avatar src={getValidAvatarUrl(user?.profilePicture)} />
                  <div className="user-info">
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontFamily: "Poppins, Regular",
                        fontWeight: "500",
                      }}
                    >
                      {user?.fullName?.length > 10
                        ? `${user.fullName.slice(0, 10)}...`
                        : user?.businessName?.length > 10
                          ? `${user.businessName.slice(0, 10)}...`
                          : user?.fullName || user?.businessName}
                    </Typography>
                    <Typography
                      className="username"
                      style={{
                        fontSize: "12px",
                        fontFamily: "Poppins, Regular",
                        fontWeight: "500",
                        color: "#63676A",
                      }}
                    >
                      @{user?.userName?.length > 10 ? `${user.userName.slice(0, 10)}...` : user?.userName}
                    </Typography>
                  </div>
                </div>
                <Button
                  variant="outlined"
                  className="following-btn"
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Poppins, Regular",
                    fontWeight: "500",
                  }}
                  onClick={(e) => manageFollow(e, user)}
                >
                  {user?.isFollowed ? "Following" : "Follow"}
                </Button>
              </div>
            );
          })}

          <Typography
            variant="body2"
            color="primary"
            style={{
              cursor: "pointer",
              textDecoration: "none",
              fontFamily: "Poppins, Medium",
              fontSize: "16px",
              fontWeight: "600",
              color: "#29008B",
            }}
            onClick={() => {
              // Add functionality to show more users here
              console.log("Show more users clicked");
            }}
          >
            Show More
          </Typography>
        </div>
      </div>
    </>
  );
}
