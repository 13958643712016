import React, { useState } from "react";
import { Button, Link } from "@mui/material";
import "../css/footer.css";
import footerline1 from "../img/footerline1.png";
import footerline2 from "../img/footerline2.png";
import footerline3 from "../img/footerline3.png";
import Navbar from "react-bootstrap/Navbar";
import activatree3 from "../img/activatree3.png";
import footerinsta from "../img/footerinsta.png";
import footerfb from "../img/footerfb.png";
import footerx from "../img/footerx.png";
import footerin from "../img/footerin.png";
import footertictok from "../img/footertictok.png";
import footerthread from "../img/footerthread.png";
import footeryoutube from "../img/footeryoutube.png";
import nextslide2 from "../img/nextslide2.png";
import { useNavigate } from "react-router-dom";
import locations from '../img/locations.png';
import emails from '../img/emails.png';

export default function Footer() {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState("home");

  const handleLinkClick = (link, path) => {
    setActiveLink(link);
    navigate(path);
  };

  const handleSignUpClick = () => {
    navigate("/signup");
  };

  const handleAboutusClick = () => {
    navigate("/aboutus");
  };

  const handleBlogClick = () => {
    navigate("/blog");
  };

  const handleResourcesClick = () => {
    navigate("/resources");
  };

  const handleInvestorsClick = () => {
    navigate("/investors");
  };
  const handleHelpCenterClick = () => {
    navigate("/helpcenter");
  };
  const handleWhatsNewClick = () => {
    navigate("/whatsnew");
  };
  const handlePrivacyPolicyClick = () => {
    navigate("/privacypolicy");
  };
  const handleTermsOfServiceClick = () => {
    navigate("/termsofservice");
  };

  return (
    <>
      <div className="App py-5">
        <img className="footerline1" src={footerline1} alt="Footer Line1" />
        <div className="container">
          <header className="App-header m-s p-5">
            <h1>
              “Begin collaborating. Bring your ideas to life. <br />Become a Entrepreneur."
            </h1>
            <img
              className="footerline2"
              src={footerline2}
              alt="Footer Line 2"
            />
            <h4>Your Journey Starts Here!</h4>
            <img
              className="footerline3"
              src={footerline3}
              alt="Footer Line 3"
              style={{
                marginLeft: "65pc",
                marginTop: "-5pc",
                width: "4%",
                position: "relative",
                height: "auto",
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSignUpClick}
              className="hover-expand-btn-collabrating"
              sx={{
                // mr: 2,
                mb: 5,
                borderRadius: "30px",
                padding: "0px",
                // ml: 2,
                color: "black",
                width: "115px",
                height: "47px",
                fontFamily: "poppins",
                fontWeight: "500",
                fontSize: "16px",
                backgroundColor: "white",
                textTransform: "none",
              }}
            >
              Join Now
              <span className="arrow-icon-collabrating">
                <img
                  src={nextslide2}
                  alt="Forword"
                  style={{
                    width: "80%",
                    position: "absolute",
                    top: "-11px",
                    right: "0px",
                  }}
                />
              </span>
            </Button>
          </header>
          <footer className="App-footer">
            <div className="row">
              <div className="col-lg-3">
                <div className="footer-section">
                  <h2>Company</h2>
                  <ul>
                    <a href="" onClick={handleAboutusClick}>
                      <li>About Us</li>
                    </a>
                    <a href="" onClick={handleBlogClick}>
                      <li>Blog</li>
                    </a>
                    <a href="" onClick={handleInvestorsClick}>
                      <li>For Investors</li>
                    </a>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="footer-section">
                  <h2>Support</h2>
                  <ul>
                    <a href="" onClick={handleHelpCenterClick}>
                      <li>Help Center</li>
                    </a>
                    <a href="" onClick={handleWhatsNewClick}>
                      <li>What's New</li>
                    </a>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="footer-section">
                  <h2>Legal</h2>
                  <ul>
                    <a href="" onClick={handlePrivacyPolicyClick}>
                      <li>Privacy Policy</li>
                    </a>
                    <a href="" onClick={handleTermsOfServiceClick}>
                      <li>Terms of Service</li>
                    </a>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="footer-section">
                  <h2>Contact Us</h2>
                  <ul>
                    <a href={`mailto: info@activatree.com`}>
                      <div className="location">
                        <div className="emails me-2 mt-2">
                          <img src={emails} alt="" />
                        </div>
                        <li style={{ margin: '0px' }}>info@activatree.com</li>
                      </div>
                    </a>
                    <a href="">
                      <div className="location">
                        <div className="emails me-2 mt-2">
                          <img src={locations} alt="" />
                        </div>
                        <li style={{ margin: '0px' }}>State of Georgia, U.S.A.</li>
                      </div>
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
          <div className="copyright">
            <div className="row">
              <div className="col-lg-6">
                <Navbar.Brand
                  href=""
                  to="/"
                  onClick={() => handleLinkClick("homepage", "/")}
                >
                  <img
                    src={activatree3}
                    alt="Activa Tree Logo"
                    style={{
                      width: "57%",
                      display: "flex",
                      marginTop: "40px",
                      marginLeft: "0px",
                      position: "unset",
                    }}
                  />
                </Navbar.Brand>
              </div>
              {/* <div className="col-lg-3"></div> */}
              <div className="col-lg-6 social-media-container">
                <div className="social-media-icons">
                  <Link
                    href="/socials"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={footerinsta}
                      alt="Instagram"
                      style={{ width: "50%", margin: "5px" }}
                    />
                  </Link>
                  <Link
                    href="/socials"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={footerfb}
                      alt="FaceBook"
                      style={{ width: "50%", margin: "5px" }}
                    />
                  </Link>
                  <Link
                    href="/socials"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={footerx}
                      alt="X"
                      style={{ width: "50%", margin: "5px" }}
                    />
                  </Link>
                  <Link
                    href="/socials"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={footerin}
                      alt="Link din"
                      style={{ width: "50%", margin: "5px" }}
                    />
                  </Link>
                  <Link
                    href="/socials"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={footertictok}
                      alt="Link din"
                      style={{ width: "50%", margin: "5px" }}
                    />
                  </Link>
                  <Link
                    href="/socials"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={footerthread}
                      alt="Link din"
                      style={{ width: "50%", margin: "5px" }}
                    />
                  </Link>
                  <Link
                    href="/socials"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={footeryoutube}
                      alt="Link din"
                      style={{ width: "50%", margin: "5px" }}
                    />
                  </Link>
                </div>
                <p className="copyright-text" style={{ fontSize: '18px' }}>Copyright © 2024 Activatree, a Subsidiary of Infinatree, Inc.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
