import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { apiUrl } from "../config/api-urls";
import { networkRequest } from "../utils/network-request";
import { errorHandler } from "../utils/error-handler";

const UserProfileContext = createContext(null);

export const UserProfileProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const location = useLocation();

  const navigate = useNavigate();

  const publicPaths = [
    "/",
    "/your-intereste",
    "/loginform",
    "/signup",
    "/aboutus",
    "/helpcenter",
    "/marketplace",
    "/newschannel",
    "/workspace",
    "/whatsnew",
    "/blog",
    "/revolutionizing",
    "/impactful",
    "/investors",
    "/termsofservice",
    "/privacypolicy",
    "/resetpassword",
    "/newpassword",
    "/discussionforum",
    "/socials",
  ];

  const checkUserProfile = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem("token");

      if (publicPaths.includes(location.pathname)) {
        return;
      }

      if (!token) {
        navigate("/loginform");
        return;
      }

      if (!userProfile) {
        const response = await networkRequest(
          "post",
          apiUrl.USER_PROFILE,
          {},
          { authorization: token }
        );
        setUserProfile(response?.data);
        setIsLoading(false);
        return;
      }
    } catch (error) {
      setIsLoading(false);
      setError(error);
      errorHandler(error);

      if (error?.response?.data?.error === "UnauthorizedError") {
        localStorage.removeItem("token");
        navigate("/loginform");
        return;
      } else {
        navigate("/");
        return;
      }
    }
  }, [userProfile, navigate]);

  return (
    <UserProfileContext.Provider
      value={{
        user: userProfile,
        isloading,
        setUserProfile,
        error,
        checkUserProfile,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};

export const useUserProfile = () => {
  return useContext(UserProfileContext);
};
