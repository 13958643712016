import React, { useRef } from "react";
import { Button } from "@mui/material";
import NavBar from "./navbar";
import "../css/homepage.css";
import "../css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import girl1 from "../img/girl1.png";
import upercommnet from "../img/upercomment.png";
import like from "../img/like.png";
import people from "../img/people.png";
import uparrow from "../img/uparrow.png";
import downcomment from "../img/downcomment.png";
import line from "../img/line.png";
import line1 from "../img/line1.png";
import dot from "../img/dot.png";
import solution1 from "../img/solution1.png";
import solution2 from "../img/solution2.png";
import globalnetwork from "../img/globalnetwork.png";
import mns from "../img/mns.png";
import collobraterealtime from "../img/collobraterealtime.png";
import pnp from "../img/pnp.png";
import lnr from "../img/lnr.png";
import ewr from "../img/ewr.png";
import fnc from "../img/fnc.png";
import ass from "../img/ass.png";
import rightarrow from "../img/rightarrow.png";
import collobrationrealtime from "../img/collobrationrealtime.png";
import momentize from "../img/momentize.png";
import grow from "../img/grow.png";
import aipowered from "../img/aipowered.png";
import singup2 from "../img/singup2.png";
import secondstage from "../img/secondstage.png";
import connectglobally from "../img/connectglobally.png";
import thirdstage from "../img/thirdstage.png";
import earnwithoutrestriction from "../img/earnwithoutrestriction.png";
import { useNavigate } from "react-router-dom";
import forword from "../img/forword.png";
import Footer from "./footer";

export default function HomePage() {
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    navigate("/signup");
  };

  const handleLoginClick = () => {
    navigate("/loginform");
  };

  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <NavBar />
          <div className="row py-5">
            <div className="col-lg-6 col-md-6">
              <div className="header">
                <ul className="title-header p-0">
                  <img className="dot" src={dot} alt="" />
                  <li className="p-2">
                    A Digital Ecosystem For Young Entrepreneurs To:
                  </li>
                </ul>
                <h2>
                  Network. Collaborate. Acquire Skills. Innovate. Showcase
                  Ideas. Build and Monetize Your Brand.
                </h2>
                <p>Turn Your CRAZY IDEAS Into REALITY</p>
                <div className="details mt-5">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLoginClick}
                    className="hover-expand-btn"
                    sx={{
                      mr: 2,
                      borderRadius: "30px",
                      padding: "0px",
                      width: "88px",
                      height: "47px",
                      fontFamily: "poppins",
                      fontWeight: "500",
                      fontSize: "16px",
                      background:
                        "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                      textTransform: "none",
                    }}
                  >
                    Login
                    <span className="arrow-icon">
                      <img
                        src={forword}
                        alt="Forword"
                        style={{ width: "80%" }}
                      />
                    </span>
                  </Button>

                  <Button
                    onClick={handleSignUpClick}
                    variant="contained"
                    color="white"
                    sx={{
                      p: 0,
                      borderRadius: "30px",
                      border: "1px solid black",
                      width: "115px",
                      height: "47px",
                      fontFamily: "poppins",
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#000",
                      textTransform: "none",
                    }}
                  >
                    Join Now
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="home-img">
                <img className="girl" src={girl1} alt="" />
                <img className="upercomment3" src={upercommnet} alt="" />
                <img className="like" src={like} alt="" />
                <img className="people" src={people} alt="" />
                <img className="uparrow" src={uparrow} alt="" />
                <img className="downcomment3" src={downcomment} alt="" />
              </div>
            </div>
          </div>
        </div>
        <img className="line" src={line} alt="" />
        <img className="line1" src={line1} alt="" />
      </div>

      <div
        className="container mb-5"
        style={{ position: "relative", top: "-20pc" }}
      >
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <div className="solution1">
              <img
                src={solution1}
                alt="Social Media Icons"
                className="image-left"
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-4">
            <div className="text-container">
              <h1>Why Choose</h1>
              <h2>Activatree</h2>
              <p>Save Time On Content Creation</p>
              <p>
                Easily integrate and manage your social media accounts from one
                Dashboard. Grow your audience globally, and build your brand.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-4">
            <div className="solution2">
              <img
                src={solution2}
                alt="Person with Tablet"
                className="image-right"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container platforms">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6">
            <div className="platform">
              <h1>
                Our Platform{" "}
                <span
                  style={{
                    color: "#9F00AA",
                    fontFamily: "poppins",
                    fontWeight: 200,
                  }}
                >
                  Features
                </span>
              </h1>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="platform">
              <p>
                Explore a suite of powerful tools designed to enhance
                collaboration, creativity and professional growth. From
                real-time co-creation to AI-powered networking, Activatree
                offers everythinng you need to succeed
              </p>
            </div>
          </div>
        </div>

        <div className="global-network row">
          <div className="col-lg-6">
            <div className="gloabal-network">
              <h2>Global Network</h2>
              <p>
              Connect globally. Expand your network. Build professional
                relationships. Build high-value teams.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="global-network-img">
              <img src={globalnetwork} alt="" />
            </div>
          </div>
        </div>

        <div className="mns row">
          <div className="col-lg-6">
            <div className="mns-text">
              <h2>Mentorship & Support</h2>
              <p>
                Connect with experienced mentors have Access to successful teen
                entrepreneurs, and solid community support system.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mns-img">
              <img src={mns} alt="" />
            </div>
          </div>
        </div>

        <div className="global-network row">
          <div className="col-lg-6">
            <div className="gloabal-network">
              <h2>Collaborate in Real-Time</h2>
              <p>
                Whether it's co-creating posts, sharing a project, or
                contributing to discussions, we make interaction simple and
                effective, to build the ideas that matter to you.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="global-network-img">
              <img src={collobraterealtime} alt="" />
            </div>
          </div>
        </div>

        <div className="mns row">
          <div className="col-lg-6">
            <div className="mns-text">
              <h2>Professional Accounts & Profiles</h2>
              <p>
                Set up Individual or Business Accounts & Profiles Pages to represent who you are and
                showcase your business
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mns-img">
              <img src={pnp} alt="" />
            </div>
          </div>
        </div>

        <div className="global-network row">
          <div className="col-lg-6">
            <div className="gloabal-network">
              <h2>Learning & Resources</h2>
              <p>
                Acquire in-demand business development skills: Entrepreneurship,
                marketing, and sales training.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="global-network-img">
              <img src={lnr} alt="" />
            </div>
          </div>
        </div>

        <div className="mns row">
          <div className="col-lg-6">
            <div className="mns-text">
              <h2>Earn Without Restriction</h2>
              <p>
                Interactive marketplace to buy and sell digital products.
                Monetize your expertise.
                Create subscription models to turn your
                content into revenue.
                 
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mns-img">
              <img src={ewr} alt="" />
            </div>
          </div>
        </div>

        <div className="global-network row">
          <div className="col-lg-6">
            <div className="gloabal-network">
              <h2>Forums & Community</h2>
              <p>
                Join discussions that support your goals, share knowledge, solve
                industry relevant problems.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="global-network-img">
              <img src={fnc} alt="" />
            </div>
          </div>
        </div>

        <div className="mns row">
          <div className="col-lg-6">
            <div className="mns-text">
              <h2>A Safe Space</h2>
              <p>
                <li>Protected Environment.</li>
                <li>Age-appropriate content. </li>
                <li>Safe networking.</li>
                <li>              
                 Privacy protection with 24/7 user support.</li>
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mns-img">
              <img src={ass} alt="" />
            </div>
          </div>
        </div>        
      </div>      

      <div className="container working-steps mt-5 py-5">
        <header className="working">
          <h1>How It <span className='works'>Works</span></h1>
          <p>Sign up for Activatree. Define your goals. Build your profile. Connect and network with professionals. Collaborate on Ideas and Projects. Build your brand, business, and team. Monetize your expertise or content.</p>
          <p><span style={{ fontWeight: '600', fontSize: '20px', color: '#000' }}>Join a thriving community and get creative.</span></p>
        </header>
        <div className="steps">
          <div className="row" style={{ gap: 23, alignItems: 'center' }}>
            <div className="col-lg-3">
              <div className="step">
                <img src={singup2} alt="Sign Up" style={{ marginBottom: '70px' }} />
                <h2>Claim Free Trial</h2>
                <p>Create your account and Build / customize your profile.</p>
              </div>
            </div>
            <img src={secondstage} alt="Stapes" className='nextstep' />
            <div className="col-lg-3">
              <div className="step">
                <img src={connectglobally} alt="Build Up Your Profile" />
                <h2>Connect Globally</h2>
                <p>Network with other creators and professionals. Develop Ideas and collaborate.</p>
              </div>
            </div>
            <img src={thirdstage} alt="Stapes" className='nextstep' />
            <div className="col-lg-3">
              <div className="step">
                <img src={earnwithoutrestriction} alt="Start Creating or Selling" />
                <h2>Earn without restriction</h2>
                <p>Monetize your Content. Sell your digital products and services.</p>
              </div>
            </div>
          </div>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSignUpClick}
          className="hover-expand-btn"
          sx={{
            mr: 2,
            borderRadius: "30px",
            padding: "0px",
            ml: 2,
            width: "115px",
            height: "47px",
            fontFamily: "poppins",
            fontWeight: "500",
            fontSize: "16px",
            background:
              "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
            textTransform: "none",
          }}
        >
          Join Now
          <span className="arrow-icon">
            <img
              src={forword}
              alt="Forword"
              style={{ width: "80%" }}
            />
          </span>
        </Button>
      </div>      
      < Footer />
    </>
  );
}
