import React, { useState } from "react";
import {
  Button,
  TextField,
  Checkbox,
  Typography,
  Link,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import google from "../img/google.png";
import { useForm } from "react-hook-form";
import { auth, googleProvider } from "../firebase/firebaseConfig";
import { signInWithPopup } from "firebase/auth";
import "../css/loginform.css";
import login from "../img/login.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import LogoImg from "./logo";
import { networkRequest } from "../utils/network-request";
import { apiUrl } from "../config/api-urls";
import { addSession } from "../redux/slice/session";
import { useDispatch } from "react-redux";
import { useUserProfile } from "../context/profile.contex";
import { useGoogleLogin } from "@react-oauth/google";

export default function LoginForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  async function responseGoogle(authResult) {
    try {
      const response = await networkRequest(
        "GET",
        apiUrl.LOGIN_GOOGLE_CALLBACK,
        {},
        {},
        { code: authResult.code }
      );

      localStorage.setItem("token", response?.data?.sessions?.[0]?.token);

      setUserProfile(response?.data);

      return navigate("/socialmedia");
    } catch (error) {
      console.log(error, "errorGoogle");
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: responseGoogle,
    flow: "auth-code",
  });

  const { user, isLoading, error, checkUserProfile, setUserProfile } =
    useUserProfile();

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  // Google Login Handler
  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log("Google Login Success:", result.user);
    } catch (error) {
      console.error("Google Login Error:", error);
    }
  };

  // Form Submission Handler
  const onSubmit = async (data) => {
    const response = await networkRequest("post", apiUrl.LOGIN, {
      email: data.email,
      password: data.password,
    });

    if (!response || !response?.data?.sessions?.[0]?.token) {
      return navigate("/signup");
    }

    localStorage.setItem("token", response?.data?.sessions?.[0]?.token);

    setUserProfile(response?.data);

    return navigate("/socialmedia");
  };

  const handleResetPasswordClick = () => {
    navigate("/resetpassword"); 
  };

  const handleSignUpClick = () => {
    navigate("/signup"); 
  };

  return (
    <>
      <div className="container">
        <LogoImg />
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="form">
              <Box sx={{ justifyContent: "center", alignItems: "center" }}>
                <div
                  className="title py-5"
                  style={{ justifyContent: "flex-start" }}
                >
                  <Typography
                    variant="h1"
                    component="h1"
                    sx={{
                      fontWeight: "700",
                      fontSize: "45px",
                      fontFamily: "poppins, semiBold",
                    }}
                  >
                    Welcome Back! You Can Log In Here.
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ mb: 4, fontFamily: "poppins", fontSize: "14px" }}
                  >
                    It's easy! Just take a minute to provide the details.
                  </Typography>
                </div>

                {/* Google Login */}
                <button className="google" onClick={googleLogin}>
                  <img
                    src={google}
                    alt="google"
                    width={35}
                    height={35}
                    style={{ padding: "10px" }}
                  />{" "}
                  <p>Continue with Google</p>
                </button>

                <div
                  className="mt-4 mb-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "450px",
                  }}
                >
                  <div
                    style={{
                      flexGrow: 1,
                      height: "1px",
                      backgroundColor: "#E0E0E0",
                    }}
                  />
                  <span
                    style={{
                      margin: "0 10px",
                      fontFamily: "poppins",
                      fontSize: "20px",
                    }}
                  >
                    or
                  </span>
                  <div
                    style={{
                      flexGrow: 1,
                      height: "1px",
                      backgroundColor: "#E0E0E0",
                    }}
                  />
                </div>

                {/* Login Form */}
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    width: "450px",
                  }}
                >
                  {/* Email Field */}
                  <TextField
                    label="Email Address"
                    required
                    sx={{
                      mb: 2,
                      "& .MuiOutlinedInput-root": { borderRadius: "30px" },
                      "& .MuiInputLabel-asterisk": { color: "red" },
                    }}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "30px",
                        backgroundColor: "#F9F9F9",
                        "& .MuiInputLabel-asterisk": { color: "red" },
                      },
                    }}
                    fullWidth
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                        message: "Enter a valid Email Address",
                      },
                    })}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ""}
                  />

                  {/* Password Field */}
                  <TextField
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    required
                    sx={{
                      mb: 2,
                      "& .MuiOutlinedInput-root": { borderRadius: "30px" },
                      "& .MuiInputLabel-asterisk": { color: "red" },
                    }}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "30px",
                        backgroundColor: "#F9F9F9",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    {...register("password", {
                      required: "Password is required",
                      pattern: {
                        message:
                          "Password must contain at least 8 characters, including uppercase, lowercase, number, and special character",
                      },
                    })}
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ""}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 3,
                      mt: 1,
                    }}
                  >
                    <Box>
                      <Checkbox />
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{ fontFamily: "poppins", fontSize: "13px" }}
                      >
                        Remember Me
                      </Typography>
                    </Box>
                    <Link
                      onClick={handleResetPasswordClick}
                      underline="none"
                      sx={{
                        fontSize: 14,
                        fontFamily: "poppins",
                        fontWeight: 600,
                        color: "#9A00A9",
                        cursor: "pointer",
                      }}
                    >
                      Reset Password
                    </Link>
                  </Box>

                  {/* Submit Button */}
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mb: 2 }}
                    style={{
                      borderRadius: "30px",
                      height: "47px",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      background:
                        "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                    }}
                    type="submit"
                  >
                    Log In
                  </Button>
                  <Typography variant="body2" align="center">
                    Don’t have an account?{" "}
                    <Link
                      href=""
                      onClick={handleSignUpClick}
                      style={{
                        textDecoration: "none",
                        fontFamily: "poppins",
                        fontWeight: "600",
                        color: "#000",
                      }}
                    >
                      Sign Up
                    </Link>
                  </Typography>
                </form>
              </Box>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="form-img ">
              <img src={login} alt="group" style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
